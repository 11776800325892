import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../helpers/pipes/pipes.module";
import { UserMenuPopover } from "./user-menu.popover";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: "",
                component: UserMenuPopover,
            },
        ]),
        PipesModule,
        TranslateModule,
    ],
    declarations: [UserMenuPopover],
    providers: [],
})
export class UserMenuPopoverModule {
}
