import { Injectable } from "@angular/core";
import { ServersConnectionsProvider } from "../../core/providers/serversConnections.provider";
import { NetworkHelper } from "../helpers/network.helper";
import { PingWs } from "../ws/ping.ws";
import { LoggerService } from "./logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class PingService {
    pinging = false;

    constructor(private logger: LoggerService,
                private pingWs: PingWs,
                private networkHelper: NetworkHelper,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public startLoop() {
        this.logger.info(this.constructor.name, "Starting ping loop");
        setInterval(() => {
                if (!this.networkHelper.isConnected() && !this.pinging) {
                    this.pinging = true;
                    this.ping()
                        .then(() => {
                            this.networkHelper.publishConnectivityChanged(true);
                            this.pinging = false;
                        })
                        .catch((reason) => {
                            this.pinging = false;
                        });

                }
            },
            5000);
    }

    public ping() {
        return this.pingWs.ping(this.serversConnectionsProvider.getServerConnection());
    }
}
