import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Address } from "./address.model";
import { Attachment } from "./attachment.model";
import { ContactDetail } from "./contactDetail.model";
import { DriverInfo } from "./driverInfo.model";

export enum Civility {
    MALE = "1",
    FEMALE = "2",
    COMPANY = "4"
}

export class ThirdParty extends ModelBase {
    static readonly TABLENAME = "ThirdParties";
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public civility: { id: Civility } = { id: Civility.MALE };
    public isActive: number = 0;

    public mainAddress: Address = null;
    public driverInformation: DriverInfo = null;

    public mail: ContactDetail = null;
    public mobile: ContactDetail = null;
    public phone: ContactDetail = null;
    public company: { id } = { id: null };

    public attachments: Attachment[] = [];

    constructor() {
        super(ThirdParty.TABLENAME);

        this.mainAddress = new Address();
        this.driverInformation = new DriverInfo();

        this.mail = new ContactDetail();
        this.mobile = new ContactDetail();
        this.phone = new ContactDetail();

        this.isActive = 0;
    }
}
