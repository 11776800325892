import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/notImplementedError";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { OfflineModeRequest } from "../../models/offlineModeRequest.model";

@Injectable({
    providedIn: "root",
})
export class OfflineModeDbDao extends DbDaoBase<OfflineModeRequest> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public getPendingRequestCount(apiFilter?: string): Promise<number> {
        let selectQuery = "SELECT COUNT(rowid) as COUNT FROM " + OfflineModeRequest.TABLENAME;
        if (!isNullOrEmpty(apiFilter)) {
            selectQuery += " WHERE api='" + apiFilter + "'";
        }
        selectQuery += ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return 0;
                }

                return data.rows[0]["COUNT"];
            })
            .catch(reason => {
                this.logSqlError(reason);
                return 0;
            });

    }

    public list(): Promise<OfflineModeRequest[]> {
        let selectQuery = "SELECT rowid, * FROM " + OfflineModeRequest.TABLENAME + ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let offlineModeRequests: OfflineModeRequest[] = [];
                for (const item of data.rows) {
                    offlineModeRequests.push(this.rowToModel(item));
                }

                let hydratationPromises = [];
                return Promise.all(hydratationPromises)
                    .then(() => {
                        return offlineModeRequests;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        const query = "CREATE INDEX IF NOT EXISTS idx_" + OfflineModeRequest.TABLENAME + "_api"
                      + " ON " + OfflineModeRequest.TABLENAME + "(api);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + OfflineModeRequest.TABLENAME
                    + " ("
                    + "api TEXT, "
                    + "date TEXT, "
                    + "mode TEXT, "
                    + "url TEXT, "
                    + "headers TEXT, "
                    + "data TEXT, "
                    + "retryCount NUMERIC"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(rowid: string): Promise<any> {
        let selectQuery = "DELETE FROM " + OfflineModeRequest.TABLENAME + " WHERE rowid='" + rowid + "';";
        return this.sqlProvider.query(selectQuery, false);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + OfflineModeRequest.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery, false);
    }

    public get(rowid: string, hydrate: boolean = false): Promise<OfflineModeRequest> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return OfflineModeRequest.TABLENAME;
    }

    protected rowToModel(row: any): OfflineModeRequest {
        let offlineModeRequest = new OfflineModeRequest();

        offlineModeRequest.rowid = row.rowid;
        offlineModeRequest.api = row.api;
        offlineModeRequest.date = row.date;
        offlineModeRequest.mode = row.mode;
        offlineModeRequest.url = row.url;
        offlineModeRequest.headers = JSON.parse(row.headers);
        offlineModeRequest.data = isNullOrEmpty(row.data) ? null : JSON.parse(row.data);
        offlineModeRequest.retryCount = row.retryCount;

        return offlineModeRequest;
    }

    public save(offlineModeRequest: OfflineModeRequest): Promise<OfflineModeRequest> {
        let query = "INSERT INTO " + OfflineModeRequest.TABLENAME + " (api, date, mode, url, headers, data, retryCount) VALUES ("
                    + this.getValue(offlineModeRequest.api)
                    + this.getValue(offlineModeRequest.date)
                    + this.getValue(offlineModeRequest.mode)
                    + this.getValue(offlineModeRequest.url)
                    + this.getValueAsJsonString(offlineModeRequest.headers)
                    + this.getValueAsJsonString(offlineModeRequest.data)
                    + this.getValue(offlineModeRequest.retryCount, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                offlineModeRequest.rowid = response.insertId;
                return offlineModeRequest;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
