import { ModelBase } from "./base/model.base";

export class KeyValue extends ModelBase {
    static readonly TABLENAME = "KeyValues";
    public key: string = "";
    public value: string = "";

    constructor(key, value) {
        super(KeyValue.TABLENAME);

        this.key = key;
        this.value = value;
    }
}
