import { DateTime } from "luxon";
import { DateHelper } from "../../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { BaseDto } from "../../../gyzmo-commons/dtos/base/base.dto";
import { Side, TyreDto } from "./tyres.dto";

export class BrakeDto extends BaseDto<void> {
    public elementId: string;
    public number: number = 0;
    public side: Side;
    public rank: number = 0;
    public wear: number = 0;
    public entryDate: DateTime = null;

    // Volatile
    public part: number = 0;
    public axle: number = 0;
    public previousWear: number = 0;

    public static fromBody(body: any, part: number, axle: number) {
        let result = new BrakeDto();

        result.elementId = body.elementId;
        result.number = body.number;
        result.side = ("" + body.side).toLowerCase() == "left" ? Side.Left : Side.Right;
        result.rank = body.rank;
        result.wear = body.wear;
        result.previousWear = body.wear;
        result.entryDate = DateHelper.tryFromFormat(body.entryDate, DATE_NODEJS_FORMAT);

        result.part = part;
        result.axle = axle;

        return result;
    }

    public static fromModel(brake): BrakeDto {
        let result = new BrakeDto();

        result.elementId = brake.elementId;
        result.number = brake.number;
        result.side = brake.side == "1" ? Side.Left : Side.Right;
        result.rank = brake.rank;
        result.wear = brake.wear;
        result.previousWear = brake.wear;

        result.entryDate = DateHelper.tryFromISO(brake.entryDate);

        result.part = brake.part;
        result.axle = brake.axle;

        return result;
    }

    public toBody() {
        const clone: any = { ...this };

        delete clone.part;
        delete clone.axle;
        delete clone.previousWear;

        clone.entryDate = DateHelper.tryToFormat(this.entryDate, DATE_NODEJS_FORMAT);
        clone.side = this.side == Side.Left ? "left" : "right";

        return clone;
    }

    public toModel(): void {
        throw new Error("Method not implemented.");
    }

    public isOnTyre(tyre: TyreDto) {
        return this.part == tyre.part
               && this.axle == tyre.axle
               && this.side == tyre.side
               && this.rank == tyre.rank;
    }
}
