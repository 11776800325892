import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../helpers/pipes/pipes.module";
import { GyzmoButtonComponent } from "./gyzmo-button.component";

@NgModule(
    {
        declarations: [
            GyzmoButtonComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
            PipesModule,
            FormsModule,
        ],
        exports: [
            GyzmoButtonComponent,
        ],
        providers: [],
    })

export class GyzmoButtonModule {
}
