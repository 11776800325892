// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/components/date-picker/date-picker-popover/date-picker-popover.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;EACA,WAAA;AACJ","sourcesContent":[".button-container {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 15px;\n    margin: 5px\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
