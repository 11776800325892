import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrEmpty } from "../null.helper";

@Pipe({ name: "capitalize" })
export class CapitalizePipe implements PipeTransform {

    transform(value: any) {
        let string = "" + value;
        if (!isNullOrEmpty(string)) {
            return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
        }

        return value;
    }
}
