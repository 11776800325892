import { ModelBase } from "./base/model.base";

export class Version extends ModelBase {
    static readonly TABLENAME = "Version";

    public id: number = 0;
    public version: string = "";

    constructor() {
        super(Version.TABLENAME);
    }
}
