import { Injectable } from "@angular/core";

import { VehiclePartDto } from "../../../../dto/wear/vehiclePart.dto";
import { HttpErrorHandler } from "../../../../http/httpErrorHandler";
import { ServerConnection } from "../../../../http/serverConnection";
import { WsDao } from "../../../../http/wsDao";
import { EquipmentWsDao } from "../../equipment.ws.dao";

@Injectable({
    providedIn: "root",
})
export class WearWsDao extends WsDao<VehiclePartDto[]> {

    constructor(private httpErrorHandler: HttpErrorHandler) {
        super();
    }

    public getById(serverConnection: ServerConnection, equipmentId: string): Promise<VehiclePartDto[]> {
        return new Promise<VehiclePartDto[]>(resolve => {
            let tokens = new Map<string, string>();
            tokens.set("equipmentId", equipmentId);

            serverConnection.get(this.constructor.name, EquipmentWsDao.WS + "/:equipmentId/wears", tokens)
                .then(response => {
                    let result = [];

                    let number = 1;
                    response.body.forEach(vehiclePart => {
                        result.push(VehiclePartDto.fromBody(vehiclePart, number));
                        number++;
                    });

                    resolve(result);
                })
                .catch(reason => {
                    return [];
                });
        });
    }

    public save(serverConnection: ServerConnection, wears: VehiclePartDto[], equipmentId?: string): Promise<VehiclePartDto[]> {
        return new Promise<VehiclePartDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("equipmentId", equipmentId);

            let body = [];
            wears.forEach(wear => {
                body.push(wear.toBody());
            });

            serverConnection.post(this.constructor.name, EquipmentWsDao.WS + "/:equipmentId/wears", tokens,
                body)
                .then(response => {
                    resolve(wears);
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject(reason);
                    }
                });
        });
    }
}
