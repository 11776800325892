import { DateTime } from "luxon";
import { DEFAULT_MAX_TANK_CAPACITY } from "../../app/app.constants";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Equipment } from "../models/equipment.model";
import { BaseDto } from "./base/base.dto";

export enum CounterKinds {
    None = "0",
    Kilometers = "1",
    Hour = "3",
}

export class EquipmentDto extends BaseDto<Equipment> {

    public id: string = "";
    public title: string = "";
    public category: { id?: string, wording?: string } = {};
    public model: string = "";
    public registrationNumber: string = "";
    public serialNumber: string = "";

    public mileage: number = 0;
    public mileageDate: DateTime = null;
    public mileage2: number = 0;
    public mileageDate2: DateTime = null;

    public inspectionModel: { id?: string } = {};

    // Personal model
    public energy1: { id?: string, wording?: string, fraction?: number } = {};
    public energy2: { id?: string, wording?: string, fraction?: number } = {};
    public counter1: { id?: string, wording?: string } = {};
    public counter2: { id?: string, wording?: string } = {};

    // Volatile, pour la recherche d'inspection
    public inspectionInProgress: { id?: string, visitDate?: DateTime } = {};

    public static fromBody(body: any): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.id = "" + body.id;
        equipmentDto.title = body.wording.trim();
        if (body.model) {
            equipmentDto.model = body.model.id;
        }

        equipmentDto.registrationNumber = body.registrationNumber;
        if (body.registration && isNullOrEmpty(equipmentDto.registrationNumber)) {
            equipmentDto.registrationNumber = body.registration.registration;
        }

        equipmentDto.serialNumber = body.serialNumber;

        if (body.personalModel) {
            if (body.personalModel.category) {
                equipmentDto.category = { id: body.personalModel.category.id, wording: body.personalModel.category.wording };
            }

            if (body.personalModel.counterKind) {
                if (body.personalModel.counterKind.id) {
                    equipmentDto.counter1.id = body.personalModel.counterKind.id;
                    equipmentDto.counter1.wording = body.personalModel.counterKind.wording;
                } else {
                    equipmentDto.counter1.id = "";
                    equipmentDto.counter1.wording = "";
                }
            }

            if (body.personalModel.counterKind2) {
                if (body.personalModel.counterKind2.id) {
                    equipmentDto.counter2.id = body.personalModel.counterKind2.id;
                    equipmentDto.counter2.wording = body.personalModel.counterKind2.wording;
                } else {
                    equipmentDto.counter2.id = "";
                    equipmentDto.counter2.wording = "";
                }
            }

            if (body.personalModel.gaugeKind) {
                if (body.personalModel.gaugeKind.fraction) {
                    equipmentDto.energy1.fraction = Number(body.personalModel.gaugeKind.fraction);
                } else {
                    equipmentDto.energy1.fraction = DEFAULT_MAX_TANK_CAPACITY;
                }
            }

            if (body.personalModel.gaugeKind2) {
                if (body.personalModel.gaugeKind2.fraction) {
                    equipmentDto.energy2.fraction = Number(body.personalModel.gaugeKind2.fraction);
                } else {
                    equipmentDto.energy2.fraction = DEFAULT_MAX_TANK_CAPACITY;
                }
            }

            if (body.personalModel.energy) {
                equipmentDto.energy1.id = body.personalModel.energy.id;
                equipmentDto.energy1.wording = body.personalModel.energy.wording;
            }

            if (body.personalModel.energy2) {
                equipmentDto.energy2.id = body.personalModel.energy2.id;
                equipmentDto.energy2.wording = body.personalModel.energy2.wording;
            }

            if (body.personalModel.inspectionModel?.id) {
                equipmentDto.inspectionModel = { id: body.personalModel.inspectionModel.id };
            }
        }

        equipmentDto.mileage = Number(body.mileageValue);
        equipmentDto.mileageDate = DateHelper.tryFromFormat(body.mileageDate, DATE_NODEJS_FORMAT);

        equipmentDto.mileage2 = Number(body.mileageValue2);
        equipmentDto.mileageDate2 = DateHelper.tryFromFormat(body.mileageDate2, DATE_NODEJS_FORMAT);

        if (body.inspectionInProgress) {
            equipmentDto.inspectionInProgress = {};
            equipmentDto.inspectionInProgress.id = body.inspectionInProgress.id;
            equipmentDto.inspectionInProgress.visitDate = DateHelper.tryFromFormat(body.inspectionInProgress.visitDate, DATE_NODEJS_FORMAT);
        }

        if (body.inspectionModel?.id) {
            equipmentDto.inspectionModel = { id: body.inspectionModel.id };
        }

        return equipmentDto;
    }

    static fromModel(equipment: Equipment): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.id = equipment.id;
        equipmentDto.title = equipment.title;
        equipmentDto.category = equipment.category;
        equipmentDto.model = equipment.model;
        equipmentDto.registrationNumber = equipment.registrationNumber;
        equipmentDto.serialNumber = equipment.serialNumber;

        equipmentDto.mileage = equipment.mileage;
        equipmentDto.mileageDate = DateTime.fromISO(equipment.mileageDate);
        equipmentDto.mileage2 = equipment.mileage2;
        equipmentDto.mileageDate2 = DateTime.fromISO(equipment.mileageDate2);

        equipmentDto.inspectionModel = equipment.inspectionModel;

        equipmentDto.energy1 = equipment.energy1;
        equipmentDto.energy2 = equipment.energy2;

        equipmentDto.counter1 = equipment.counter1;
        equipmentDto.counter2 = equipment.counter2;

        return equipmentDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        // Nettoyer l'object avant envoi a lpservice
        delete clone.picture;

        delete clone.mileageDate;
        delete clone.mileageDate2;

        return clone;
    }

    toModel() {
        let equipment = new Equipment();

        equipment.id = this.id;
        equipment.title = this.title;
        equipment.category = this.category;
        equipment.model = this.model;
        equipment.registrationNumber = this.registrationNumber;
        equipment.serialNumber = this.serialNumber;

        equipment.mileage = this.mileage;
        equipment.mileageDate = DateHelper.tryToISO(this.mileageDate);
        equipment.mileage2 = this.mileage2;
        equipment.mileageDate2 = DateHelper.tryToISO(this.mileageDate2);

        equipment.inspectionModel = this.inspectionModel;

        equipment.energy1 = this.energy1;
        equipment.energy2 = this.energy2;

        equipment.counter1 = this.counter1;
        equipment.counter2 = this.counter2;

        return equipment;
    }
}

