import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../helpers/pipes/pipes.module";
import { IriumGallery } from "./irium-gallery";
import { IriumGalleryDocumentModule } from "./irium-gallery-document/irium-gallery-document.module";

@NgModule(
    {
        declarations: [
            IriumGallery,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
            PipesModule,
            FormsModule,
            IriumGalleryDocumentModule,
        ],
        exports: [
            IriumGallery,
        ],
    })

export class IriumGalleryModule {
}
