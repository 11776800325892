// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  white-space: nowrap !important;
}

.with-bottom-border {
  border-bottom: var(--with-bottom-border-color) thin solid;
}

.center {
  margin: auto;
}

ion-textarea {
  --padding-top: 0;
}

ion-select {
  min-width: 100%;
  width: 100%;
}

irium-gallery {
  --flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/app/inspection/V2/modal/create-damage/create-damage.modal.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AAEA;EACI,yDAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;AACJ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":["ion-label {\n    white-space: nowrap !important;\n}\n\n.with-bottom-border {\n    border-bottom: var(--with-bottom-border-color) thin solid;\n}\n\n.center {\n    margin: auto;\n}\n\nion-textarea {\n    --padding-top: 0;\n}\n\nion-select {\n    min-width: 100%;\n    width: 100%;\n}\n\nirium-gallery {\n    --flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
