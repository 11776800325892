import { AST, Parser } from "node-sql-parser";
import { SQLiteResult, SqlProvider } from "../interfaces/sqlProvider";
import { LoggerService } from "../services/logs/logger.service";

export class SqlHelper {
    public static parseSql(sqlFileContent: string) {
        const parser = new Parser();
        const astList: AST[] | AST = parser.astify(sqlFileContent);

        let lines = [];
        if (Array.isArray(astList)) {
            astList.forEach(ast => {
                lines.push(parser.sqlify(ast));
            });
        } else {
            lines.push(parser.sqlify(astList));
        }

        return lines;
    }

    public static async runSqlFile(sqlDriver: SqlProvider, sqlFileContent: string, logger: LoggerService) {
        logger.info(this.constructor.name, "Parsing sql file...");
        let lines = this.parseSql(sqlFileContent);
        logger.info(this.constructor.name, lines.length + " commands found.");

        for (const line of lines) {
            await sqlDriver.query(line)
                .catch(error => {
                    logger.error(this.constructor.name, SqlHelper.stringifySqlError(error));
                });
        }
    }

    public static stringifySqlError(error: any) {
        if (error.err) {
            return "SqlError; Code : " + error.err.code + "; Message : " + error.err.message;
        } else if (error.code) {
            return "SqlError; Code : " + error.code + "; Message : " + error.message;
        } else {
            return "SqlError; " + error;
        }
    }

    public static stringifySqlResult(sqLiteResult: SQLiteResult) {
        let result = "[";
        for (let i = 0; i < sqLiteResult.rows.length; i++) {
            result += JSON.stringify(sqLiteResult.rows[i]);
            if (i < (sqLiteResult.rows.length - 1)) {
                result += ",\n";
            }
        }
        result += "]";
        result = result.replace(/"/g, "'");

        return result;
    }
}
