import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { NotImplementedError } from "../../gyzmo-commons/helpers/notImplementedError";
import { OfflineModeRequest } from "../models/offlineModeRequest.model";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";

export class OfflineModeRequestDto extends BaseDto<void> {
    public rowid = 0;
    public api: string = "";
    public date: DateTime = null;
    public mode: string = "";
    public url: string = "";
    public headers = {};
    public data = {};
    public retryCount = 0;

    static fromModel(offlineModeRequest: OfflineModeRequest): OfflineModeRequestDto {
        let offlineModeRequestDto = new OfflineModeRequestDto();

        offlineModeRequestDto.rowid = offlineModeRequest.rowid;
        offlineModeRequestDto.api = offlineModeRequest.api;
        offlineModeRequestDto.date = DateTime.fromISO(offlineModeRequest.date);
        offlineModeRequestDto.mode = offlineModeRequest.mode;
        offlineModeRequestDto.url = offlineModeRequest.url;
        offlineModeRequestDto.headers = offlineModeRequest.headers;
        offlineModeRequestDto.data = offlineModeRequest.data;
        offlineModeRequestDto.retryCount = offlineModeRequest.retryCount;

        return offlineModeRequestDto;
    }

    public toBody() {
        throw new NotImplementedError();
    }

    toModel() {
        let offlineModeRequest = new OfflineModeRequest();

        offlineModeRequest.rowid = this.rowid;
        offlineModeRequest.api = this.api;
        offlineModeRequest.date = DateHelper.tryToISO(this.date);
        offlineModeRequest.mode = this.mode;
        offlineModeRequest.url = this.url;
        offlineModeRequest.data = this.data;
        offlineModeRequest.headers = this.headers;
        offlineModeRequest.retryCount = this.retryCount;

        return offlineModeRequest;
    }
}

