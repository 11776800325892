// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-row {
  height: 100%;
}

.container {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.backgroundCanvas {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

.signaturePad {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

ion-fab {
  position: relative;
}

.secondary-toolbar div {
  display: flex;
  flex-wrap: wrap;
}
.secondary-toolbar .selected {
  border-bottom: 4px solid var(--ion-color-primary);
  padding-bottom: 0;
}
.secondary-toolbar .separator {
  margin: 10px 5px;
  padding: 0;
  border-left: thin solid var(--ion-color-medium-light);
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/modals/draw/draw.modal.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGI;EACI,aAAA;EACA,eAAA;AAAR;AAGI;EACI,iDAAA;EACA,iBAAA;AADR;AAII;EACI,gBAAA;EACA,UAAA;EACA,qDAAA;AAFR","sourcesContent":[".container-row {\n    height: 100%;\n}\n\n.container {\n    display: inline-block;\n    position: relative;\n    overflow: hidden;\n    margin: auto;\n}\n\n.backgroundCanvas {\n    position: absolute;\n    top: 0;\n    left: 0;\n    max-width: 100%;\n    max-height: 100%;\n}\n\n.signaturePad {\n    position: absolute;\n    top: 0;\n    left: 0;\n    max-width: 100%;\n    max-height: 100%;\n}\n\nion-fab {\n    position: relative;\n}\n\n.secondary-toolbar {\n    div {\n        display: flex;\n        flex-wrap: wrap;\n    }\n\n    .selected {\n        border-bottom: 4px solid var(--ion-color-primary);\n        padding-bottom: 0;\n    }\n\n    .separator {\n        margin: 10px 5px;\n        padding: 0;\n        border-left: thin solid var(--ion-color-medium-light);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
