import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../helpers/null.helper";
import { IServerConnection } from "../interfaces/IServerConnection";

@Injectable({
    providedIn: "root",
})
export class GeneralParametersWs {
    static readonly WS = "technical/general-parameters";

    public getValue(serverConnection: IServerConnection, table: string, argument: string): Promise<string> {
        return new Promise<string>((resolve) => {
            let tokens = new Map<string, string>();
            tokens.set("table", table);
            tokens.set("argument", argument);

            serverConnection.get(this.constructor.name, GeneralParametersWs.WS + "?table=:table&argument=:argument", tokens, false, false, true)
                .then(response => {
                    if (Array.isArray(response.body)) {
                        resolve(response.body[0].value);
                    } else if (!isNullOrEmpty(response.body.value)) {
                        resolve(response.body.value);
                    } else {
                        resolve(null);
                    }
                })
                .catch(reason => {
                    resolve(null);
                });
        });
    }
}
