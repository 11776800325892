import { Injectable } from "@angular/core";
import { LoadingController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ProgressDialogDriver, ProgressDialogPopover } from "../popovers/progress-dialog/progress-dialog.popover";

@Injectable({
    providedIn: "root",
})
export class LoadingHelper {
    private loading: HTMLIonLoadingElement = null;
    private popover: HTMLIonPopoverElement = null;
    private level: number = 0;

    constructor(private loadingCtrl: LoadingController,
                private popoverController: PopoverController,
                private translateService: TranslateService) {
    }

    public async showLoading(msgKey?: string) {
        this.level++;

        if (this.loading == null) {
            this.loading = await this.loadingCtrl.create({
                spinner: "circles",
                animated: true,
                message: msgKey ? this.translateService.instant(msgKey) : this.translateService.instant("IMCP_Please_wait..."),
            });
            await this.loading.present();
        }
    }

    public async hideLoading() {
        this.level--;

        if (this.level <= 0) {
            await this.hideAll();
        }
    }

    public async hideAll() {
        this.level = 0;

        if (this.loading) {
            await this.loading.dismiss();
            this.loading = null;
        }

        if (this.popover) {
            await this.popover.dismiss();
            this.popover = null;
        }
    }

    public isVisible(): boolean {
        return this.loading != null || this.popover != null;
    }

    public async showProgress(progressDialogDriver: ProgressDialogDriver) {
        this.popover = await this.popoverController.create({
            component: ProgressDialogPopover,
            componentProps: {
                progressDialogDriver: progressDialogDriver,
            },
            translucent: true,
            backdropDismiss: false,
            animated: true,
            showBackdrop: true,
        });

        await this.popover.present();
    }

    public async hideProgress() {
        await this.popover.dismiss();
        this.popover = null;
    }
}
