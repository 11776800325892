export enum AttachmentKinds {
    PHOTO = "PHOTO",
    FICHIERS = "FICHIERS",
    ID_FRONT = "ID_FRONT",
    ID_REAR = "ID_REAR",
    DRIVER_LICENSE_FRONT = "LICENSE_FRONT",
    DRIVER_LICENSE_REAR = "LICENSE_REAR",
    SELFIE = "SELFIE",
    CGL = "CGL",
    CGU = "CGU",
    MODE_EMPLOI = "MODE_EMPLOI",
    DESCRIPTION_VEHICULE = "DESCMAT",
    OPTIONS_VEHICULE = "OPTMAT",
    PHOTO_VEHICULE = "PHOTO_VEHICULE",
    TEXTE = "TXT",
    SIGNATURE = "SIGNATURE",
    SIGNATURETEC = "SIGNATURETEC",
    POLITIQUE_CONFID = "POLITIQUE_CONFID"
}
