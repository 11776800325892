import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class MaintenanceControl extends ModelBase {
    static readonly TABLENAME = "MaintenanceControls";

    public id: string = "";
    public equipmentId: string = "";
    public wording: string = "";
    public plannedMinDate: string = "";
    public plannedDate: string = "";
    public plannedMaxDate: string = "";
    public order: number = 0;

    constructor() {
        super(MaintenanceControl.TABLENAME);
    }
}
