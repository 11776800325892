import { Component, ViewChild } from "@angular/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { IonRouterOutlet, NavController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Settings } from "luxon";
import { register } from "swiper/element/bundle";
import { HttpErrorHandler } from "../core/http/httpErrorHandler";
import { DaoProvider } from "../core/persistence/dao.provider";
import { ServersConnectionsProvider } from "../core/providers/serversConnections.provider";
import { DefaultDataDownloaderService } from "../core/services/defaultDataDownloader.service";
import { OfflineModeService } from "../core/services/offlineMode.service";
import { UserService } from "../core/services/user.service";
import { KeyValueDbDao } from "../gyzmo-commons/dao/db/keyValue.db.dao";
import { MOCK_VERSION_KEY } from "../gyzmo-commons/dtos/mockVersion.dto";
import { VersionDto } from "../gyzmo-commons/dtos/version.dto";
import { DeviceHelper } from "../gyzmo-commons/helpers/device.helper";
import { NetworkHelper } from "../gyzmo-commons/helpers/network.helper";
import { isNullOrEmpty } from "../gyzmo-commons/helpers/null.helper";
import { ToastHelper } from "../gyzmo-commons/helpers/toast.helper";
import { VersionHelper } from "../gyzmo-commons/helpers/version.helper";
import { DatabaseLocation } from "../gyzmo-commons/interfaces/databaseLocation";
import { KeyValue } from "../gyzmo-commons/models/keyValue.model";
import { AppSqlProvider } from "../gyzmo-commons/persistence/app.sql.provider";
import { Database } from "../gyzmo-commons/persistence/database";
import { AppVersionService } from "../gyzmo-commons/services/appVersion.service";
import { BiometryService } from "../gyzmo-commons/services/biometry.service";
import { CacheService } from "../gyzmo-commons/services/cache.service";
import { DatabaseVersionService } from "../gyzmo-commons/services/databaseVersion.service";
import { LanguageService } from "../gyzmo-commons/services/language.service";
import { LoggerService } from "../gyzmo-commons/services/logs/logger.service";
import { PingService } from "../gyzmo-commons/services/ping.service";
import { MODULE } from "./app.constants";

register();

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent {
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
    isInitialized = false;
    logout = false;
    login: { user: string; password: string } = null;
    mockConfig = "";

    constructor(platform: Platform,
                private logger: LoggerService,
                private appSqlProvider: AppSqlProvider,
                private database: Database,
                private navController: NavController,
                private deviceHelper: DeviceHelper,
                private networkHelper: NetworkHelper,
                private appVersionService: AppVersionService,
                private databaseVersionService: DatabaseVersionService,
                private userService: UserService,
                private translateService: TranslateService,
                private toastHelper: ToastHelper,
                private httpErrorHandler: HttpErrorHandler,
                private serversConnectionsProvider: ServersConnectionsProvider,
                private languageService: LanguageService,
                private offlineModeService: OfflineModeService,
                private daoProvider: DaoProvider,
                private cacheService: CacheService,
                private keyValueDbDao: KeyValueDbDao,
                private biometryService: BiometryService,
                /*
                 private assetsHelper: AssetsHelper,
                 private modalController: ModalController,
                 private inspectionDbDaoV2: InspectionServiceV2,
                 */
                private pingService: PingService,
                //private appUpdateService: AppUpdateService,
                private defaultDataDownloaderService: DefaultDataDownloaderService) {
        this.logout = !isNullOrEmpty(this.getParameterByName("logout"));
        if (!isNullOrEmpty(this.getParameterByName("login"))) {
            let param = this.getParameterByName("login").split(/[ +]/);
            this.login = { user: param[0], password: param[1] };
        }
        if (!isNullOrEmpty(this.getParameterByName("mockConfig"))) {
            this.mockConfig = this.getParameterByName("mockConfig");
        }

        void platform.ready().then(async () => {
            await this.initialize();
            await this.start();
        });
    }

    async initialize() {
        await this.deviceHelper.initialize();
        this.logger.initialize();
        this.networkHelper.initialize();
        await this.biometryService.initialize();

        this.logger.info(this.constructor.name, "App is running on ", this.deviceHelper.getPlatform());

        await this.initializeSql();

        this.logger.info(this.constructor.name, "User languages : " + this.languageService.getUserLanguages());

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang("en");

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.changeLanguage(this.languageService.getUserLanguage());

        this.navController.setTopOutlet(this.routerOutlet);

        await this.serversConnectionsProvider.initialize();

        this.httpErrorHandler.getUserDisconnectedObservable()
            .subscribe(async () => {
                await this.userService.disconnect(false)
                    .then(async userWasConnected => {
                        if (userWasConnected) {
                            await this.toastHelper.show(this.translateService.instant("IMCP_You_ve_been_disconnected_please_reconnect."));
                        }
                        await this.navController.navigateRoot("/connection");
                    });
            });

        this.isInitialized = true;
        this.logger.info(this.constructor.name, "**************************** App is initialized ****************************");
    }

    private changeLanguage(language: string) {
        this.logger.info(this.constructor.name, "Set language to " + language);
        this.translateService.use(language);
        Settings.defaultLocale = language;
    }

    private getParameterByName(name: any) {
        let url = window.location.href;
        name = name.replace(/[[]]/g, "$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/[+]/g, " "));
    }

    private async initializeSql() {
        // Okay, so the platform is ready and our plugins are available.
        // Here you can do any higher level native things you might need.
        await this.appSqlProvider.initialize(MODULE, DatabaseLocation.default, this.deviceHelper.getDeviceSerial());

        this.logger.info(this.constructor.name, "**************************** SQL is initialized ****************************");

        let currentAppVersion = await this.appVersionService.getAppVersion();
        let currentDbVersion: VersionDto = new VersionDto(0, "0");
        if ((await this.appSqlProvider.getExistingTables()).indexOf("Versions") >= 0) {
            currentDbVersion = await this.databaseVersionService.getDatabaseVersion();
        }
        this.logger.info(this.constructor.name, "Database Version=" + currentDbVersion.version);
        this.logger.info(this.constructor.name, "App Version=" + currentAppVersion.version);

        await this.database.initialize(
            this.daoProvider.getAllDaoList(),
            this.daoProvider.getOnDisconnectDaoList(),
            currentAppVersion,
            currentDbVersion);

        if (currentDbVersion.version != "0" && VersionHelper.compareVersions(currentAppVersion.toDigits(), currentDbVersion.toDigits()) > 0) {
            if (await this.userService.isConnected()) {
                await this.userService.disconnect(false);
            }
        }

        await this.cacheService.cleanCache();
    }

    private async start() {
        this.offlineModeService.startSynchronisationTask();
        this.pingService.startLoop();

        if (!isNullOrEmpty(this.mockConfig)) {
            await this.keyValueDbDao.save(new KeyValue(MOCK_VERSION_KEY, this.mockConfig));
        }

        if (this.logout) {
            await this.userService.disconnect(true);
            await this.navController.navigateRoot("/connection");
        } else if (this.login) {
            await this.userService.disconnect(false);

            let user = await this.userService.connect(this.login.user, this.login.password);

            this.appSqlProvider.enableBulkWriting();
            await this.defaultDataDownloaderService.onConnectionDefaultData(user);
            await this.appSqlProvider.commitBulk();

            await this.keyValueDbDao.save(new KeyValue("LAST_LOGIN", user.username));
            await this.keyValueDbDao.save(new KeyValue("CGU_" + user.username, "1"));
            await this.keyValueDbDao.save(new KeyValue("PRIVACY_POLICY_" + user.username, "1"));

            await this.navController.navigateRoot("/about");
        } else {
            let user = await this.userService.getCurrentUserWithThirdPartyOnly();
            if (user) {
                let cguAccepted = await this.keyValueDbDao.get("CGU_" + user.username);
                let privacyPolicyAccepted = await this.keyValueDbDao.get("PRIVACY_POLICY_" + user.username);

                if (cguAccepted == null || cguAccepted.value != "1") {
                    await this.navController.navigateRoot("/cgu");
                } else if (privacyPolicyAccepted == null || privacyPolicyAccepted.value != "1") {
                    await this.navController.navigateRoot("/privacy-policy");
                } else {
                    /*
                     let inspectionDto = await this.inspectionDbDaoV2.getByIdOnline("N9LS5HVUH00010FZ001K", true);
                     inspectionDto.mileage++;
                     inspectionDto.mileage2++;
                     inspectionDto.movement.startMileage++;
                     inspectionDto.movement.startMileage2++;
                     inspectionDto.movement.returnMileage++;
                     inspectionDto.movement.returnMileage++;

                     for (const checklist of inspectionDto.checklists) {
                     checklist.elements[0].savedValue = checklist.elements[0].possibleValues[1].id;
                     checklist.elements[0].previousValue = checklist.elements[0].possibleValues[0].id;

                     for (const index of [0, 1, 2]) {
                     index;
                     let attachment = new AttachmentDto();
                     attachment.attachmentKind.id = AttachmentKinds.PHOTO;
                     attachment.file = await this.assetsHelper.readImageAsBase64("default_photo.jpg");

                     checklist.elements[0].attachments.push(attachment);
                     }
                     }

                     let damage = new DamageDto();
                     damage.isOld = false;
                     inspectionDto.faces[0].zones[0].damages.push(damage);

                     //await this.navController.navigateRoot("/inspection-summary-v2", { inspection: inspectionDto, comment: "bonjour !" });
                     //await this.navController.navigateRoot("/movement-departure", { inspection: inspectionDto });
                     //await this.navController.navigateRoot("/movement-return", { inspection: inspectionDto });
                     //await this.navController.navigateRoot("/mileage-entry", { inspection: inspectionDto });
                     //await this.navController.navigateRoot("/face-v2/0", { inspection: inspectionDto });
                     //await this.navController.navigateRoot("/face-general-photo-v2", { inspection: inspectionDto });
                     await this.navController.navigateRoot("/inspection-list-v2");
                     //await this.navController.navigateForward("/checklist-v2", { inspection: inspectionDto });
                     //await this.navController.navigateRoot("/inspection-list-list");

                     const x = 0;
                     const y = 0;

                     const newDamage = new DamageDto();
                     newDamage.xAxis = x;
                     newDamage.yAxis = y;
                     newDamage.isOld = false;
                     newDamage.zoneId = inspectionDto.faces[0].zones[0].id;
                     newDamage.inspectionId = inspectionDto.id;
                     newDamage.updateDate = DateTime.now();

                     for (const index of [0, 1, 2]) {
                     index;
                     let attachment = new AttachmentDto();
                     attachment.attachmentKind.id = AttachmentKinds.PHOTO;
                     attachment.file = await this.assetsHelper.readImageAsBase64("default_photo.jpg");

                     newDamage.attachments.push(attachment);
                     }

                     void this.modalController.create({
                     component: CreateDamageModal,
                     componentProps: { damage: newDamage, isCreation: true, zone: inspectionDto.faces[0].zones[0], inspectionKind: inspectionDto.kind },
                     backdropDismiss: false,
                     })
                     .then(profileModal => {
                     //void profileModal.present();
                     });
                     */
                    await this.navController.navigateRoot("/inspection-list-v2");
                }
            } else {
                await this.navController.navigateRoot("/connection");
            }

            if (this.deviceHelper.isRunningOnDevice()) {
                await CapacitorUpdater.notifyAppReady();

                // Le setTimeout permet d'attendre que le home soit bien affiché et cache les popover et modals affichés par leur instanciations.
                setTimeout(async () => {
                    await SplashScreen.hide();
                }, 500);
            }
        }
    }
}
