import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
    selector: "date-picker-popover",
    templateUrl: "date-picker-popover.component.html",
    styleUrls: ["date-picker-popover.component.scss"],
})
export class DatePickerPopoverComponent {
    @Input() date: string;
    @Input() fromDate: string;
    @Input() toDate: string;

    constructor(private popoverController: PopoverController) {
    }

    async closeDatePickerPopover() {
        const modal = await this.popoverController.getTop();
        await modal.dismiss("");
    }

    async validateDatePickerPopover() {
        const modal = await this.popoverController.getTop();
        await modal.dismiss({ date: (this.date) });
    }
}
