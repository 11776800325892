import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Address } from "../../models/address.model";

@Injectable({
    providedIn: "root",
})
export class AddressDbDao extends DbDaoBase<Address> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Address.TABLENAME + "_id"
                    + " ON " + Address.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Address.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "address1 TEXT, "
                    + "address2 TEXT, "
                    + "address3 TEXT, "
                    + "address4 TEXT, "
                    + "zipCode TEXT, "
                    + "city TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Address.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Address.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Address> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Address.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Address.TABLENAME;
    }

    protected rowToModel(row: any): Address {
        let address = new Address();
        address.id = row.id;
        address.address1 = row.address1;
        address.address2 = row.address2;
        address.address3 = row.address3;
        address.address4 = row.address4;
        address.zipCode = row.zipCode;
        address.city = row.city;

        return address;
    }

    public save(address: Address): Promise<Address> {
        let query = "INSERT OR REPLACE INTO " + Address.TABLENAME + " (id, address1, address2, address3, address4, zipCode, city) VALUES ("
                    + this.getValue(address.id)
                    + this.getValue(address.address1)
                    + this.getValue(address.address2)
                    + this.getValue(address.address3)
                    + this.getValue(address.address4)
                    + this.getValue(address.zipCode)
                    + this.getValue(address.city, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return address;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
