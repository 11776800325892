import { Injectable } from "@angular/core";
import { Device, DeviceInfo } from "@capacitor/device";

export enum PlatformName {
    UNKNOWN = "Unknown",
    ANDROID = "Android",
    IOS = "iOS",
    BROWSER = "Browser",
    ELECTRON = "Electron",
    JEST = "Jest"
}

@Injectable({
    providedIn: "root",
})
export class DeviceHelper {
    private uuid: string = "";
    private deviceInfo: DeviceInfo;

    public getPlatform(): PlatformName {
        try {
            // @ts-ignore
            if (process.env.JEST_WORKER_ID !== undefined) {
                return PlatformName.JEST;
            }
        } catch {
            // ignored
        }

        if (!this.deviceInfo) {
            console.error("DeviceHelper not initialized ! Wait for platform.ready and call initialize before using it !");
            throw new Error("DeviceHelper not initialized ! Wait for platform.ready and call initialize before using it !");
        }

        switch (this.deviceInfo.platform) {
            case "web":
                return PlatformName.BROWSER;
            case "ios":
                return PlatformName.IOS;
            case "android":
                return PlatformName.ANDROID;
        }

        return PlatformName.UNKNOWN;
    }

    public isRunningOnDevice() {
        let platform1 = this.getPlatform();
        return platform1 == PlatformName.ANDROID || platform1 == PlatformName.IOS;
    }

    getDeviceSerial() {
        return this.uuid;
    }

    public initialize() {
        return Device.getInfo()
            .then(async (deviceInfo: DeviceInfo) => {
                this.deviceInfo = deviceInfo;

                if (this.isRunningOnDevice()) {
                    this.uuid = (await Device.getId()).identifier.replace("-", "").substring(0, 19);
                } else {
                    this.uuid = "no-serial";
                }
            });
    }
}
