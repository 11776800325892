import { CustomisationDto, CustomisationI18NLabelsDto } from "../dto/customisation.dto";
import { InspectionDto } from "../dto/inspection/v2/inspection.dto";
import { MaintenanceControlDto } from "../dto/maintenanceControl.dto";

export class InspectionState {
    inspection: InspectionDto = null;
    maintenanceControls: MaintenanceControlDto[] = null;
    comment: string = "";
    generalPhotoTypes: CustomisationI18NLabelsDto[] = null;
    customisation: CustomisationDto = null;

    constructor(inspection: InspectionDto,
                maintenanceControls: MaintenanceControlDto[],
                customisation: CustomisationDto) {
        this.inspection = inspection;
        this.maintenanceControls = maintenanceControls;
        this.customisation = customisation;
        this.generalPhotoTypes = customisation.generalPictures;
    }
}
