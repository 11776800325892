import { Component, Input } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { DamageValueDto } from "../../../../../core/dto/inspection/common/damageValue.dto";
import { DamageDto } from "../../../../../core/dto/inspection/v2/damage.dto";
import { InspectionDto } from "../../../../../core/dto/inspection/v2/inspection.dto";
import { ZoneElementDto } from "../../../../../core/dto/inspection/v2/zoneElement.dto";
import { ZoneElementService } from "../../../../../core/services/inspection/v2/zoneElement.service";

@Component({
    selector: "modal-view-damage",
    templateUrl: "view-damages.modal.html",
    styleUrls: ["view-damages.modal.scss"],
})
export class ViewDamagesModal {
    @Input() inspection: InspectionDto;
    zoneElements: ZoneElementDto[];

    constructor(private platform: Platform,
                private viewCtrl: ModalController,
                private zoneElementService: ZoneElementService) {
    }

    ionViewWillEnter() {
        this.platform.backButton.subscribeWithPriority(999, () => {
            this.dismiss();
        });

        void this.zoneElementService.getList()
            .then(values => {
                this.zoneElements = values;
            });
    }

    public dismiss() {
        void this.viewCtrl.dismiss();
    }

    getDamageCount() {
        let count = 0;

        this.inspection.faces.forEach(face => {
            face.zones.forEach(zone => {
                count += zone.damages.length;
            });
        });

        return count;
    }

    getDamageValue(damageDto: DamageDto): DamageValueDto {
        if (!this.zoneElements) {
            return null;
        }

        let zoneElement = this.zoneElements.find(element => {
            return element.id == damageDto.elementId;
        });
        if (!zoneElement) {
            return null;
        }

        let damageValue = zoneElement.damageCategory.possibleValues.find(element => {
            return element.categoryId == zoneElement.damageCategory.id && element.id == damageDto.value;
        });

        return damageValue;
    }

    getElementValue(damageDto: DamageDto): ZoneElementDto {
        if (!this.zoneElements) {
            return null;
        }

        let zoneElement = this.zoneElements.find(element => {
            return element.id == damageDto.elementId;
        });

        return zoneElement;
    }

    getDamageResponsibility(damage: DamageDto) {
        let result = "";

        if (damage.clientResponsibility === "1") {
            result = "Client";
        } else if (damage.clientResponsibility === "2") {
            result = "Loueur";
        } else if (damage.clientResponsibility === "0") {
            result = "Non défini";
        }

        return result;
    }
}
