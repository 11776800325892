import { Injectable } from "@angular/core";
import { NetworkHelper } from "../helpers/network.helper";
import { IServerConnection } from "../interfaces/IServerConnection";
import { PingWs } from "../ws/ping.ws";
import { LoggerService } from "./logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class PingService {
    pinging = false;

    constructor(private logger: LoggerService,
                private pingWs: PingWs,
                private networkHelper: NetworkHelper) {
    }

    public startLoop(serverConnection: IServerConnection) {
        this.logger.info(this.constructor.name, "Starting ping loop");
        setInterval(() => {
                if (!this.networkHelper.isConnected() && !this.pinging) {
                    this.pinging = true;
                    this.ping(serverConnection)
                        .then(() => {
                            this.networkHelper.publishConnectivityChanged(true);
                            this.pinging = false;
                        })
                        .catch((reason) => {
                            this.pinging = false;
                        });

                }
            },
            5000);
    }

    private ping(serverConnection: IServerConnection) {
        return this.pingWs.ping(serverConnection);
    }
}
