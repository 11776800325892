import { Component, forwardRef, Input } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";

@Component({
    selector: "irium-select",
    templateUrl: "./irium-select.component.html",
    styleUrls: ["./irium-select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => IriumSelectComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => IriumSelectComponent),
        },
    ],
})
export class IriumSelectComponent implements ControlValueAccessor, Validator {
    @Input() possibleValues: { id: string, wording: string }[] = [];
    @Input() label: string = "";
    @Input() placeholder: string = "";
    @Input() mandatory: boolean = false;
    @Input() disabled: boolean = false;
    @Input() labelPlacement: "fixed" | "floating" | "stacked" = "stacked";
    @Input() line: boolean = true;
    @Input() justify: "start" | "end" | "space-between" = "start";

    value: number = 0;
    touched = false;

    onChange = (value) => {
    };

    onTouched = () => {
    };

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        this.value = value;
    }

    markAsTouched() {
        this.onTouched();
        this.touched = true;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }

    public onSelect($event: any) {
        this.value = $event.detail.value;
        this.onChange(this.value);
        this.markAsTouched();
    }
}
