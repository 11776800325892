export const LANGUAGE_KEY = "LANGUAGE";

export enum LANGUAGES {
    ENGLISH = "en",
    FRENCH = "fr",
    GERMAN = "de",
    SPANISH = "es",
    PORTUGUESE = "pt",
    DUTCH = "nl"
}
