import { Injectable } from "@angular/core";
import { Geolocation } from "@capacitor/geolocation";
import { DateTime } from "luxon";
import { DeviceHelper } from "../helpers/device.helper";
import { isNullOrEmpty } from "../helpers/null.helper";
import { DateProvider } from "../interfaces/dateProvider";
import { LoggerService } from "./logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class GeolocationService {
    lastCoordinates: { latitude: number, longitude: number, altitude: number } = null;
    lastCoordinatesDate: DateTime = null;
    deviceWatchId = "";
    browserWatchId = null;

    TIMEOUT = 1500;
    MAX_AGE = 30000;

    constructor(private deviceHelper: DeviceHelper,
                private logger: LoggerService,
                private dateProvider: DateProvider) {
    }

    public async initialize() {
        if (this.deviceHelper.isRunningOnDevice()) {
            await Geolocation.requestPermissions({ permissions: ["location", "coarseLocation"] })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                });
            this.deviceWatchId = await Geolocation.watchPosition({ maximumAge: this.MAX_AGE - 1, enableHighAccuracy: true, timeout: this.TIMEOUT },
                (position, err) => {
                    if (err) {
                        this.logger.error(this.constructor.name, err);
                    }

                    if (position) {
                        this.lastCoordinatesDate = this.dateProvider.now();
                        this.lastCoordinates = { latitude: position.coords.latitude, longitude: position.coords.longitude, altitude: position.coords.altitude != null ? position.coords.altitude : 0 };

                        this.logger.debug(this.constructor.name, "Gps coordinates acquired", this.lastCoordinates);
                    }
                });
        } else {
            if (navigator.geolocation) {
                this.browserWatchId = navigator.geolocation.watchPosition(
                    position => {
                        this.lastCoordinatesDate = this.dateProvider.now();
                        this.lastCoordinates = { latitude: position.coords.latitude, longitude: position.coords.longitude, altitude: position.coords.altitude != null ? position.coords.altitude : 0 };

                        this.logger.debug(this.constructor.name, "Gps coordinates acquired", this.lastCoordinates);
                    },
                    positionError => {
                        switch (positionError.code) {
                            case positionError.PERMISSION_DENIED:
                                this.logger.error(this.constructor.name, positionError.message + "(PERMISSION_DENIED)");
                                break;
                            case positionError.POSITION_UNAVAILABLE:
                                this.logger.error(this.constructor.name, positionError.message + "(POSITION_UNAVAILABLE)");
                                break;
                            case positionError.TIMEOUT:
                                this.logger.error(this.constructor.name, positionError.message + "(TIMEOUT)");
                                break;
                        }
                    },
                    { maximumAge: this.MAX_AGE - 1, enableHighAccuracy: true, timeout: this.TIMEOUT });
            }
        }
    }

    public async dispose() {
        if (!isNullOrEmpty(this.deviceWatchId)) {
            await Geolocation.clearWatch({ id: this.deviceWatchId });
        }

        if (this.browserWatchId != null) {
            navigator.geolocation.clearWatch(this.browserWatchId);
        }
    }

    public getCurrentPosition(): Promise<{ latitude: number, longitude: number, altitude: number } | null> {
        return Promise.resolve(this.lastCoordinates);
    }
}
