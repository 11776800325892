export abstract class BaseDto<T> {
    public static getId(object: any): string {
        if (object.id) {
            return object.id;
        }

        return object;
    }

    protected getId(object: any): string {
        return BaseDto.getId(object);
    }

    public abstract toBody(): any;

    public abstract toModel(): T;
}
