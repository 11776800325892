import { Checklist } from "../../../models/inspection/v2/checklist.model";
import { BaseDto } from "../../base/base.dto";
import { ChecklistElementDto } from "./checklistElement.dto";

export class ChecklistDto extends BaseDto<Checklist> {
    public id: string = "";
    public wording: string = "";
    public order: string = "";

    public elements: ChecklistElementDto[] = [];

    static fromBody(body: any, inspectionId: string): ChecklistDto {
        let checklistDto = new ChecklistDto();

        checklistDto.id = "" + body.id;
        checklistDto.wording = body.wording;
        checklistDto.order = body.order;

        if (body.elements) {
            body.elements.forEach(element => {
                checklistDto.elements.push(ChecklistElementDto.fromBody(element, inspectionId, checklistDto.id));
            });
        }

        return checklistDto;
    }

    static fromModel(checklist: Checklist) {
        let checklistDto = new ChecklistDto();

        checklistDto.id = checklist.id;
        checklistDto.wording = checklist.wording;
        checklistDto.order = checklist.order;

        if (checklist.elements) {
            checklist.elements.forEach(element => {
                checklistDto.elements.push(ChecklistElementDto.fromModel(element));
            });
        }

        return checklistDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.elements = [];
        this.elements.forEach(element => {
            clone.elements.push(element.toBody());
        });

        delete clone.wording;

        return clone;
    }

    public toModel(): Checklist {
        let checklist = new Checklist();

        checklist.id = this.id;
        checklist.wording = this.wording;
        checklist.order = this.order;

        if (this.elements) {
            this.elements.forEach(element => {
                checklist.elements.push(element.toModel());
            });
        }

        return checklist;
    }
}
