import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/notImplementedError";
import { VersionHelper } from "../../../gyzmo-commons/helpers/version.helper";
import { XVEGAMINVERSION } from "../../../gyzmo-commons/http/header.constant";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { UserDto } from "../../dto/user.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";

@Injectable({
    providedIn: "root",
})
export class UserWsDao extends WsDao<UserDto> {
    static readonly WS = "users";

    constructor(private thirdPartyWsDao: ThirdPartyWsDao,
                private translateService: TranslateService) {
        super();
    }

    public connect(serverConnection: ServerConnection, login: string, password: string): Promise<{ token: string, minVersion: number[], user: UserDto }> {
        return new Promise<{ token: string, minVersion: number[], user: UserDto }>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = UserWsDao.WS + "/login?details=true";
            if (environment.mocked) {
                url += "&user=" + login + "&password=" + password;
            }

            serverConnection.post(this.constructor.name, url, tokens, {
                username: login,
                password: password,
            }, false)
                .then(response => {
                    let token = response.body.token;
                    let userDto = UserDto.fromBody(response.body);
                    let minVersionString = response.headers.get(XVEGAMINVERSION.toLowerCase());
                    let minVersion = VersionHelper.parseVersion(minVersionString);
                    resolve({ token: token, minVersion: minVersion, user: userDto });
                })
                .catch(reason => {
                    if (reason.status != 0) {
                        reject(this.translateService.instant("IONIC_Connection_error_please_check_your_login_password"));
                    } else {
                        reject(this.translateService.instant("IONIC_Connection_error_please_check_that_you_are_under_network_coverage."));
                    }
                });
        });
    }

    public disconnect(serverConnection: ServerConnection, remoteLogout: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            if (remoteLogout) {
                let tokens = new Map<string, string>();
                serverConnection.delete(this.constructor.name, UserWsDao.WS + "/me/logout", tokens)
                    .then(async () => {
                        await serverConnection.setAuthentication(serverConnection.getServerDto().defaultToken);
                        resolve();
                    })
                    .catch(async () => {
                        await serverConnection.setAuthentication(serverConnection.getServerDto().defaultToken);
                        resolve();
                    });
            } else {
                if (serverConnection) {
                    serverConnection.setAuthentication(serverConnection.getServerDto().defaultToken);
                }

                resolve();
            }
        });
    }

    public checkToken(serverConnection: ServerConnection): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, "technical/token-validation", tokens, false)
                .then(response => {
                    resolve(true);
                })
                .catch(reason => {
                    if (reason.status == 401) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
        });
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<UserDto> {
        return new Promise<UserDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, UserWsDao.WS + "/:id", tokens)
                .then(response => {
                    let user = UserDto.fromBody(response.body);

                    this.thirdPartyWsDao.getById(serverConnection, user.thirdParty.id)
                        .then((thirdParty: ThirdPartyDto) => {
                            user.thirdParty = thirdParty;
                            resolve(user);
                        })
                        .catch(reason => {
                            reject(reason);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, userDto: UserDto): Promise<UserDto> {
        throw new NotImplementedError();
    }

    public updatePassword(serverConnection: ServerConnection, actualPassword: string, newPassword: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, UserWsDao.WS + "/me/reset-password", tokens, {
                oldPassword: actualPassword,
                newPassword: newPassword,
            })
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public lostPassword(serverConnection: ServerConnection, username: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, UserWsDao.WS + "/lost-password", tokens, { username: username })
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getMe(serverConnection: ServerConnection): Promise<UserDto> {
        return new Promise<UserDto>((resolve, reject) => {
            serverConnection.get(this.constructor.name, UserWsDao.WS + "/me", null)
                .then(response => {
                    let user = UserDto.fromBody(response.body);

                    this.thirdPartyWsDao.getById(serverConnection, user.thirdParty.id)
                        .then((thirdParty: ThirdPartyDto) => {
                            user.thirdParty = thirdParty;
                            resolve(user);
                        })
                        .catch(reason => {
                            reject(reason);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
