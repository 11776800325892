import { BaseDto } from "../../../gyzmo-commons/dtos/base/base.dto";
import { BrakeDto } from "./brake.dto";
import { TyreDto } from "./tyres.dto";

export class AxleDto extends BaseDto<void> {
    public number: number;
    public tyres: TyreDto[] = [];
    public brakes: BrakeDto[] = [];

    // Volatile
    public part: number = 0;

    public static fromBody(body: any, part: number) {
        let result = new AxleDto();

        result.number = body.number;

        body.tyres.forEach(tyre => {
            result.tyres.push(TyreDto.fromBody(tyre, part, result.number));
        });
        body.brakes.forEach(brake => {
            result.brakes.push(BrakeDto.fromBody(brake, part, result.number));
        });

        result.part = part;

        return result;
    }

    public static fromModel(axle): AxleDto {
        let result = new AxleDto();

        result.number = axle.number;
        result.part = axle.part;

        axle.tyres.forEach(tyre => {
            result.tyres.push(TyreDto.fromModel(tyre));
        });
        axle.brakes.forEach(brake => {
            result.brakes.push(BrakeDto.fromModel(brake));
        });

        return result;
    }

    public toBody() {
        const clone: any = { ...this };

        delete clone.part;

        clone.tyres = [];
        this.tyres.forEach(tyre => {
            clone.tyres.push(tyre.toBody());
        });

        clone.brakes = [];
        this.brakes.forEach(brake => {
            clone.brakes.push(brake.toBody());
        });

        return clone;
    }

    public toModel(): void {
        throw new Error("Method not implemented.");
    }
}
