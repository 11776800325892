import { Injectable } from "@angular/core";
import { VersionDbDao } from "../dao/db/version.db.dao";
import { VersionDto } from "../dtos/version.dto";

@Injectable({
    providedIn: "root",
})
export class DatabaseVersionService {
    constructor(private versionDbDao: VersionDbDao) {
    }

    public async getDatabaseVersion(): Promise<VersionDto> {
        let value = await this.versionDbDao.getCurrentVersion();
        return VersionDto.fromModel(value);
    }
}
