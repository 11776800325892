import { NgModule } from "@angular/core";
import { DateWs } from "../../../gyzmo-commons/ws/date.ws";
import { GeneralParametersWs } from "../../../gyzmo-commons/ws/generalParameters.ws";
import { ThirdPartyTypesWs } from "../../../gyzmo-commons/ws/thirdPartyTypes.ws";
import { AttachmentWsDao } from "./attachment.ws.dao";
import { CompanyWsDao } from "./company.ws.dao";
import { ContactDetailsWsDao } from "./contactDetails.ws.dao";
import { CustomisationWsDao } from "./customisation.ws.dao";
import { EquipmentWsDao } from "./equipment.ws.dao";
import { DamageCategoryWsDao } from "./inspection/common/damageCategory.ws.dao";
import { InspectionFacesWsDaoV2 } from "./inspection/v2/face.ws.dao";
import { InspectionWsDaoV2 } from "./inspection/v2/inspection.ws.dao";
import { LocationWsDao } from "./location.ws.dao";
import { MovementWsDao } from "./movement.ws.dao";
import { OfflineModeWsDao } from "./offlineMode.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { UserWsDao } from "./user.ws.dao";

@NgModule({
    providers: [
        AttachmentWsDao,
        CompanyWsDao,
        ContactDetailsWsDao,
        DamageCategoryWsDao,
        EquipmentWsDao,
        LocationWsDao,
        MovementWsDao,
        OfflineModeWsDao,
        ThirdPartyWsDao,
        UserWsDao,
        ThirdPartyTypesWs,
        DateWs,
        GeneralParametersWs,
        InspectionFacesWsDaoV2,
        InspectionWsDaoV2,
        CustomisationWsDao,
    ],
})
export class WsModule {
}
