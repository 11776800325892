import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Address extends ModelBase {
    static readonly TABLENAME = "Address";

    public id: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public address4: string = "";
    public zipCode: string = "";
    public city: string = "";

    constructor() {
        super(Address.TABLENAME);
    }
}
