import { Component } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { CustomisationDto } from "../../../../core/dto/customisation.dto";
import { CustomisationService } from "../../../../core/services/customisation.service";

@Component({
    selector: "maintenance-control-help",
    templateUrl: "maintenance-control-help.popover.html",
    styleUrls: ["maintenance-control-help.popover.scss"],
})
export class MaintenanceControlHelpPopover implements ViewWillEnter {
    customisation: CustomisationDto;

    constructor(private customisationService: CustomisationService) {
    }

    async ionViewWillEnter() {
        this.customisation = await this.customisationService.getCustomisation();
    }
}

