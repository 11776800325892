import { NgModule } from "@angular/core";
import { CacheDbDao } from "../../../gyzmo-commons/dao/db/cache.db.dao";
import { KeyValueDbDao } from "../../../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../../../gyzmo-commons/dao/db/version.db.dao";
import { AddressDbDao } from "./address.db.dao";
import { AttachmentDbDao } from "./attachment.db.dao";
import { ContactDetailsDbDao } from "./contactDetails.db.dao";
import { DriverInfoDbDao } from "./driverInfo.db.dao";
import { EquipmentDbDao } from "./equipment.db.dao";
import { FtRecordDbDao } from "./ftRecord.db.dao";
import { DamageCategoryDbDao } from "./inspection/common/damageCategory.db.dao";
import { DamageValuesDbDao } from "./inspection/common/damageValues.db.dao";
import { GroupCategoryDbDao } from "./inspection/common/groupCategory.db.dao";
import { ChecklistDbDaoV2 } from "./inspection/v2/checklist.db.dao";
import { ChecklistElementDbDaoV2 } from "./inspection/v2/checklistElement.db.dao";
import { DamageDbDaoV2 } from "./inspection/v2/damage.db.dao";
import { FaceDbDaoV2 } from "./inspection/v2/face.db.dao";
import { InspectionDbDaoV2 } from "./inspection/v2/inspection.db.dao";
import { ZoneDbDaoV2 } from "./inspection/v2/zone.db.dao";
import { ZoneElementDbDaoV2 } from "./inspection/v2/zoneElement.db.dao";
import { MovementDbDao } from "./movement.db.dao";
import { OfflineModeDbDao } from "./offlineMode.db.dao";
import { ServerDbDao } from "./server.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";
import { UserDbDao } from "./user.db.dao";

@NgModule({
    imports: [],
    providers: [
        AddressDbDao,
        AttachmentDbDao,
        ContactDetailsDbDao,
        DamageCategoryDbDao,
        DamageValuesDbDao,
        DriverInfoDbDao,
        EquipmentDbDao,
        GroupCategoryDbDao,
        KeyValueDbDao,
        MovementDbDao,
        ServerDbDao,
        ThirdPartyDbDao,
        UserDbDao,
        VersionDbDao,
        OfflineModeDbDao,
        CacheDbDao,
        ChecklistDbDaoV2,
        ChecklistElementDbDaoV2,
        DamageDbDaoV2,
        FaceDbDaoV2,
        InspectionDbDaoV2,
        ZoneDbDaoV2,
        ZoneElementDbDaoV2,
        FtRecordDbDao
    ],
})
export class DaoModule {
}
