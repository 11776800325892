import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "uppercase" })
export class UppercasePipe implements PipeTransform {

    transform(value: string) {
        if (value) {
            return value.toUpperCase();
        }
        return value;
    }

}
