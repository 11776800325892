import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Address } from "./address.model";
import { Company } from "./company.model";

export class Location extends ModelBase {
    static readonly TABLENAME = "Locations";

    public id: string = "";
    public mainAddress: Address = null;
    public wording: string = "";
    public company: Company = null;

    constructor() {
        super(Location.TABLENAME);

        this.mainAddress = new Address();
        this.company = new Company();
    }
}
