import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Location } from "../../models/location.model";
import { Sector } from "../../models/sector.model";

@Injectable({
    providedIn: "root",
})
export class SectorDbDao extends DbDaoBase<Sector> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Sector.TABLENAME + "_id"
                    + " ON " + Sector.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Sector.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Sector.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Sector.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Sector> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Sector.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                let sector: Sector = this.rowToModel(data.rows[0]);

                return sector;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Location.TABLENAME;
    }

    protected rowToModel(row: any): Sector {
        let location = new Sector();
        location.id = row.id;
        location.wording = row.wording;

        return location;
    }

    public save(location: Sector): Promise<Sector> {
        let query = "INSERT OR REPLACE INTO " + Sector.TABLENAME + " (id, wording) VALUES ("
                    + this.getValue(location.id)
                    + this.getValue(location.wording, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return location;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
