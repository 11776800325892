import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { User } from "../../models/user.model";
import { LocationDbDao } from "./location.db.dao";
import { SectorDbDao } from "./sector.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable({
    providedIn: "root",
})
export class UserDbDao extends DbDaoBase<User> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider,
                private thirdPartyDbDao: ThirdPartyDbDao,
                private locationDbDao: LocationDbDao,
                private sectorDbDao: SectorDbDao) {
        super(logger);
    }

    public getCurrentUserWithThirdPartyOnly(): Promise<User> {
        return this.get(null, false)
            .then(user => {
                if (user) {
                    return this.thirdPartyDbDao.get(user.thirdParty.id, false)
                        .then(thirdParty => {
                            user.thirdParty = thirdParty;
                            return user;
                        });
                } else {
                    return null;
                }
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + User.TABLENAME + "_id"
                    + " ON " + User.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + User.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "username TEXT, "
                    + "thirdParty TEXT, "
                    + "location TEXT, "
                    + "sector TEXT, "
                    + "wording TEXT,"
                    + "language TEXT,"
                    + "company TEXT,"
                    + "profile TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + User.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    public deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + User.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean): Promise<User> {
        let selectQuery = "SELECT * FROM " + User.TABLENAME + ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                let user: User = this.rowToModel(data.rows[0]);

                let hydratationPromises = [];
                if (hydrate) {
                    hydratationPromises.push(this.thirdPartyDbDao.get(user.thirdParty.id, hydrate)
                        .then(value => {
                            user.thirdParty = value;
                        }));
                    hydratationPromises.push(this.locationDbDao.get(user.location.id, hydrate)
                        .then(value => {
                            user.location = value;
                        }));
                    hydratationPromises.push(this.sectorDbDao.get(user.sector.id, hydrate)
                        .then(value => {
                            user.sector = value;
                        }));
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return user;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return User.TABLENAME;
    }

    protected rowToModel(row: any): User {
        let user = new User();
        user.id = row.id;
        user.username = row.username;
        user.thirdParty.id = row.thirdParty;
        user.location.id = row.location;
        user.sector.id = row.sector;
        user.wording = row.wording;
        user.language = row.language;
        user.company = JSON.parse(row.company);
        user.profile = JSON.parse(row.profile);

        return user;
    }

    public save(user: User): Promise<User> {
        let promises = [];

        if (user.thirdParty) {
            promises.push(this.thirdPartyDbDao.save(user.thirdParty));
        }

        if (user.location) {
            promises.push(this.locationDbDao.save(user.location));
        }

        if (user.sector) {
            promises.push(this.sectorDbDao.save(user.sector));
        }

        return Promise.all(promises)
            .then(() => {
                let query = "INSERT OR REPLACE INTO " + User.TABLENAME + " (id, username, thirdParty, location, sector, language, wording, company, profile) VALUES ("
                            + this.getValue(user.id)
                            + this.getValue(user.username)
                            + this.getFkValue(user.thirdParty)
                            + this.getFkValue(user.location)
                            + this.getFkValue(user.sector)
                            + this.getValue(user.language)
                            + this.getValue(user.wording)
                            + this.getValueAsJsonString(user.company)
                            + this.getValueAsJsonString(user.profile, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return user;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
