import { Component, Input } from "@angular/core";
import { AssetsHelper } from "../helpers/assets.helper";
import { isNullOrEmpty } from "../helpers/null.helper";

@Component({
    template: "",
})
export abstract class CameraBasePage {
    @Input() photoType: string;
    @Input() inspectionModel: string;

    protected constructor(protected assetsHelper: AssetsHelper) {
    }

    protected async getTemplateFilename(photoType: string, inspectionModel: string): Promise<string> {
        if (!isNullOrEmpty(photoType)) {
            let filename = "";

            switch (photoType) {
                case "AVANT":
                    filename = inspectionModel + "/front.svg";
                    break;
                case "ARRIERE":
                    filename = inspectionModel + "/rear.svg";
                    break;
                case "DROITE":
                    filename = inspectionModel + "/right.svg";
                    break;
                case "GAUCHE":
                    filename = inspectionModel + "/left.svg";
                    break;
                case "3_4_AR_DR":
                    filename = inspectionModel + "/3_4_rear_right.svg";
                    break;
                case "3_4_AR_GA":
                    filename = inspectionModel + "/3_4_rear_left.svg";
                    break;
                case "3_4_AV_DR":
                    filename = inspectionModel + "/3_4_front_right.svg";
                    break;
                case "3_4_AV_GA":
                    filename = inspectionModel + "/3_4_front_left.svg";
                    break;
            }

            if (!isNullOrEmpty(filename)) {
                let exists = await this.assetsHelper.exists(filename);

                if (exists) {
                    return "assets/" + filename;
                }
            }

            return null;
        }
    }

}
