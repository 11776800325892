import { Injectable } from "@angular/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { ModalController } from "@ionic/angular";
import { DeviceHelper } from "../helpers/device.helper";
import { PICTURE_QUALITY, PICTURE_RESOLUTION } from "../interfaces/constants";
import { BrowserCameraModal } from "../modals/browser-camera/browserCamera.modal";

@Injectable({
    providedIn: "root",
})
export class CameraService {
    constructor(public deviceHelper: DeviceHelper,
                public modalController: ModalController) {
    }

    public async getPicture(photoType?: string,
                            inspectionModel?: string,
                            resolution: { width: number; height: number } = PICTURE_RESOLUTION,
                            exportQuality: number = PICTURE_QUALITY,
                            showDebugInfo = false): Promise<string> {
        if (this.deviceHelper.isRunningOnDevice()) {
            await Camera.requestPermissions();

            const image = await Camera.getPhoto({
                quality: exportQuality ? exportQuality : 100,
                allowEditing: false,
                width: resolution ? resolution.width : null,
                height: resolution ? resolution.height : null,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera,
                presentationStyle: "fullscreen",
                correctOrientation: true,
                saveToGallery: false,
            });

            return image.dataUrl;
        } else {
            return new Promise<string>(
                (resolve, reject) => {
                    this.modalController.create({
                        component: BrowserCameraModal,
                        backdropDismiss: false,
                        componentProps: {
                            photoType: photoType,
                            inspectionModel: inspectionModel,
                            showDebugInfo: showDebugInfo,
                            width: resolution ? resolution.width : null,
                            height: resolution ? resolution.height : null,
                            exportQuality: exportQuality,
                        },
                    })
                        .then(modal => {
                            modal.present();
                            modal.onDidDismiss().then(result => {
                                if (result?.data) {
                                    resolve(result.data);
                                } else {
                                    reject(new Error("Cancelled"));
                                }
                            });
                        });
                });
        }
    }

    public async getPictureFromGallery(): Promise<string> {
        if (this.deviceHelper.isRunningOnDevice()) {
            await Camera.requestPermissions();
        }

        const image = await Camera.getPhoto({
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Photos,
            presentationStyle: "fullscreen",
        });

        return image.dataUrl;
    }
}
