import { BaseDto } from "../../../gyzmo-commons/dtos/base/base.dto";
import { AxleDto } from "./axle.dto";

export class VehiclePartDto extends BaseDto<void> {
    public axles: AxleDto[] = [];

    // Volatile
    public number: number;

    public static fromBody(body: any, number: number): VehiclePartDto {
        let result = new VehiclePartDto();

        result.number = number;

        body.axles.forEach(axle => {
            result.axles.push(AxleDto.fromBody(axle, number));
        });

        return result;
    }

    public static fromModel(wear: any): VehiclePartDto {
        let result = new VehiclePartDto();

        result.number = wear.number;

        wear.axles.forEach(axle => {
            result.axles.push(AxleDto.fromModel(axle));
        });

        return result;
    }

    public toBody() {
        const clone: any = { ...this };

        delete clone.number;

        clone.axles = [];
        this.axles.forEach(axle => {
            clone.axles.push(axle.toBody());
        });

        return clone;
    }

    public toModel(): void {
        throw new Error("Method not implemented.");
    }
}
