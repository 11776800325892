import { Company } from "../models/company.model";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";

export class CompanyDto extends BaseDto<Company> {
    public id: string = "";
    public wording: string = "";
    public phone: string = "";
    public mail: string = "";

    public static fromBody(body: any): CompanyDto {
        let companyDto = new CompanyDto();

        companyDto.id = "" + body.id;
        companyDto.phone = body.phone;
        companyDto.mail = body.mail;
        companyDto.wording = body.wording;

        return companyDto;
    }

    public static fromModel(company: Company): CompanyDto {
        let companyDto = new CompanyDto();

        companyDto.id = company.id;
        companyDto.wording = company.wording;
        companyDto.phone = company.phone;
        companyDto.mail = company.mail;

        return companyDto;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): Company {
        let company = new Company();

        company.id = this.id;
        company.wording = this.wording;
        company.phone = this.phone;
        company.mail = this.mail;

        return company;
    }
}
