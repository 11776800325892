import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export enum ContactDetailTypes {
    PHONE = "1",
    MAIL = "2",
    MOBILE = "3",
    FAX = "4",
}

export class ContactDetail extends ModelBase {
    static readonly TABLENAME = "ContactDetails";
    public id: string = "";
    public type: { id: string } = { id: ContactDetailTypes.PHONE };
    public data: string = "";
    public wording: string = "";

    constructor() {
        super(ContactDetail.TABLENAME);
    }
}
