import { ServerConfigurationSpec } from "../../environments/environment.model.specif";
import { NotImplementedError } from "../../gyzmo-commons/helpers/notImplementedError";
import { Server } from "../models/server.model";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";

export class ServerDto extends BaseDto<Server> {
    id: number = 0;
    name: string = "";
    url: string = "";
    apiKey: string = "";
    defaultToken: string = "";
    token: string = "";
    context: string = "";
    inspectionApiVersion: number = 2;

    static fromModel(server: Server): ServerDto {
        let serverDto = new ServerDto();

        serverDto.id = server.id;
        serverDto.name = server.name;
        serverDto.apiKey = server.apiKey;
        serverDto.url = server.url;
        serverDto.defaultToken = server.defaultToken;
        serverDto.token = server.token;
        serverDto.context = server.context;
        serverDto.inspectionApiVersion = server.inspectionApiVersion;

        return serverDto;
    }

    static fromServerConfiguration(serverConfiguration: ServerConfigurationSpec): ServerDto {
        let serverDto = new ServerDto();

        serverDto.id = 0;
        serverDto.name = serverConfiguration.name;

        serverDto.defaultToken = "";
        if (serverConfiguration.defaultToken) {
            serverDto.defaultToken = serverConfiguration.defaultToken;
        }

        serverDto.token = "";
        if (serverConfiguration.token) {
            serverDto.token = serverConfiguration.token;
        }

        serverDto.apiKey = "";
        if (serverConfiguration.apiKey) {
            serverDto.apiKey = serverConfiguration.apiKey;
        }

        serverDto.url = "";
        if (serverConfiguration.url) {
            serverDto.url = serverConfiguration.url;
        }

        serverDto.context = "";
        if (serverConfiguration.context) {
            serverDto.context = serverConfiguration.context;
        }

        return serverDto;
    }

    public toBody(): any {
        throw new NotImplementedError();
    }

    toModel() {
        let server = new Server();

        server.id = this.id;
        server.name = this.name;
        server.apiKey = this.apiKey;
        server.url = this.url;
        server.defaultToken = this.defaultToken;
        server.token = this.token;
        server.context = this.context;
        server.inspectionApiVersion = this.inspectionApiVersion;

        return server;
    }
}

