import { Injectable } from "@angular/core";
import { UserDto } from "../dto/user.dto";
import { AttachmentService } from "./attachment.service";
import { CustomisationService } from "./customisation.service";
import { DamageCategoryService } from "./inspection/common/damageCategory.service";

@Injectable({ providedIn: "root" })
export class DefaultDataDownloaderService {
    constructor(private damageCategoryService: DamageCategoryService,
                private customisationService: CustomisationService,
                private attachmentService: AttachmentService) {
    }

    public async onConnectionDefaultData(user: UserDto): Promise<void> {
        let promises = [];

        promises.push(this.damageCategoryService.synchronize());
        promises.push(this.customisationService.synchronizeCustomisation(user.company.id));
        promises.push(this.attachmentService.cacheByCompanyId(user.company.id));
        promises.push(this.attachmentService.cacheByCompanyId("IRIUM"));

        await Promise.all(promises);
    }

    public async checkDefaultData(): Promise<{ correctness: boolean; message: string }> {
        let correctness = await this.damageCategoryService.checkDamageCategoryData();
        if (!correctness) {
            return { correctness, message: "DamageCategories vide" };
        }

        correctness = await this.damageCategoryService.checkPossibleValuesData();
        if (!correctness) {
            return { correctness, message: "PossibleValues vide" };
        }

        return { correctness, message: "" };
    }
}
