import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "highlight" })
export class HighlightPipe implements PipeTransform {
    transform(text: string, search: string): string {
        if (!search) {
            return text;
        }
        const regex: RegExp = new RegExp(search.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&")
            .split(" ").filter(t => t.length > 0).join("|"));
        return text.replace(regex, match => `<span class="highlight-search">${ match }</span>`);
    }
}
