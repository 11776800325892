import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Equipment extends ModelBase {
    static readonly TABLENAME = "Equipments";

    public id: string = "";
    public title: string = "";
    public category: { id?: string, wording?: string } = {};
    public model: string = "";
    public registrationNumber: string = "";
    public serialNumber: string = "";

    public mileage: number = 0;
    public mileageDate: string = "";
    public mileage2: number = 0;
    public mileageDate2: string = "";

    public inspectionModel: { id?: string } = {};

    // Personal model
    public energy1: { id?: string, wording?: string, fraction?: number } = {};
    public energy2: { id?: string, wording?: string, fraction?: number } = {};
    public counter1: { id?: string, wording?: string } = {};
    public counter2: { id?: string, wording?: string } = {};

    constructor() {
        super(Equipment.TABLENAME);
    }
}

