import { IonicConfig } from "@ionic/core";

export const API_VERSION: number = 2;
export const MODULE: string = "10002";

export const DEFAULT_MAX_TANK_CAPACITY: number = 8;

export const GENERAL_PHOTOS = true;

export const TESTS_ROOT_URL = "http://localhost:4202";

export const INSPECTION_SAVE_UPDATE_STC = false;
export const INSPECTION_SAVE_GENERATE_DAMAGE = false;
export const INSPECTION_SAVE_UPDATE_MOVEMENT = true;

export const APP_OPTIONS: IonicConfig =
    {
        //mode: 'ios', // Pour forcer en mode ios
        scrollPadding: false,
        scrollAssist: false,
    };
