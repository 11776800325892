import { Component, Input, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Dimensions, ImageCropperComponent, ImageTransform } from "ngx-image-cropper";
import { LoadingHelper } from "../../helpers/loading.helper";

@Component({
    templateUrl: "crop.modal.html",
    styleUrls: ["crop.modal.scss"],
})
export class CropModal {
    @ViewChild("imageCropper") imageCropper: ImageCropperComponent;

    @Input() imageData: string = "";
    @Input() exportQuality: number = 80;

    transform: ImageTransform = {
        flipV: false,
        flipH: false,
        translateUnit: "px",
    };
    canvasRotation = 0;

    constructor(private modalController: ModalController,
                private loadingHelper: LoadingHelper) {
    }

    async cropperReady(sourceImageDimensions: Dimensions) {
        await this.loadingHelper.hideAll();
    }

    public dismiss() {
        void this.modalController.dismiss();
    }

    public save() {
        let result = this.imageCropper.crop("base64");
        void this.modalController.dismiss({ base64: result.base64 });
    }

    public async rotateLeft() {
        await this.loadingHelper.showLoading();

        setTimeout(() => { // Use timeout because rotating image is a heavy operation and will block the ui thread
            this.canvasRotation--;
            this.flipAfterRotate();
        });
    }

    public async rotateRight() {
        await this.loadingHelper.showLoading();

        setTimeout(() => { // Use timeout because rotating image is a heavy operation and will block the ui thread
            this.canvasRotation++;
            this.flipAfterRotate();
        });
    }

    public flipVertically() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };
    }

    public flipHorizontally() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH,
        };
    }
}
