import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { ContactDetail } from "../../models/contactDetail.model";

@Injectable({
    providedIn: "root",
})
export class ContactDetailsDbDao extends DbDaoBase<ContactDetail> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + ContactDetail.TABLENAME + "_id"
                    + " ON " + ContactDetail.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + ContactDetail.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "type TEXT, "
                    + "data TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + ContactDetail.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    public deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + ContactDetail.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<ContactDetail> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + ContactDetail.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return ContactDetail.TABLENAME;
    }

    protected rowToModel(row: any): ContactDetail {
        let contactDetail = new ContactDetail();
        contactDetail.id = row.id;
        contactDetail.type = JSON.parse(row.type);
        contactDetail.data = row.data;

        return contactDetail;
    }

    public save(contactDetails: ContactDetail): Promise<ContactDetail> {
        let query = "INSERT OR REPLACE INTO " + ContactDetail.TABLENAME + " (id, type, data) VALUES ("
                    + this.getValue(contactDetails.id)
                    + this.getValueAsJsonString(contactDetails.type)
                    + this.getValue(contactDetails.data, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return contactDetails;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
