import { Injectable } from "@angular/core";
import { FtRecordDbDao } from "../dao/db/ftRecord.db.dao";
import { TyreWsDao } from "../dao/ws/tyre.ws.dao";
import { FtRecordDto } from "../dto/ftRecord.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class TyreService {
    constructor(private tyreWsDao: TyreWsDao,
                private ftRecordDbDao: FtRecordDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public synchronizeBrands(): Promise<void> {
        return this.tyreWsDao.getBrandList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(brands => {
                let promises = [];

                brands.forEach(brand => {
                    promises.push(this.ftRecordDbDao.save(brand.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }

    public async getBrands() {
        let tyreBrands = await this.ftRecordDbDao.listByObject("tyreBrand");

        return tyreBrands.map(tyreBrand => {
            return FtRecordDto.fromModel(tyreBrand);
        });
    }

    public synchronizeSizes(): Promise<void> {
        return this.tyreWsDao.getSizeList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(sizes => {
                let promises = [];

                sizes.forEach(size => {
                    promises.push(this.ftRecordDbDao.save(size.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }

    public async getSizes() {
        let tyreBrands = await this.ftRecordDbDao.listByObject("tyreSize");

        return tyreBrands.map(tyreBrand => {
            return FtRecordDto.fromModel(tyreBrand);
        });
    }
}
