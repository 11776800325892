// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `image-cropper {
  --cropper-outline-color: transparent;
  --cropper-overlay-color: transparent;
}

.secondary-toolbar div {
  display: flex;
  flex-wrap: wrap;
}
.secondary-toolbar .separator {
  margin: 10px 5px;
  padding: 0;
  border-left: thin solid var(--ion-color-medium-light);
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/modals/crop/crop.modal.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,oCAAA;AACJ;;AAGI;EACI,aAAA;EACA,eAAA;AAAR;AAGI;EACI,gBAAA;EACA,UAAA;EACA,qDAAA;AADR","sourcesContent":["image-cropper {\n    --cropper-outline-color: transparent;\n    --cropper-overlay-color: transparent;\n}\n\n.secondary-toolbar {\n    div {\n        display: flex;\n        flex-wrap: wrap;\n    }\n\n    .separator {\n        margin: 10px 5px;\n        padding: 0;\n        border-left: thin solid var(--ion-color-medium-light);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
