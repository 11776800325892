import { Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { INSPECTION_KINDS } from "../../core/interfaces/INSPECTION_KINDS";
import { InspectionState } from "../../core/interfaces/inspectionState";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { GENERAL_PHOTOS } from "../app.constants";

export class InspectionRouter {
    public static async navigateToNextPage(navController: NavController,
                                           router: Router,
                                           inspectionState: InspectionState,
                                           url?: string) {
        if (!url) {
            let urlTokens = router.url.split(new RegExp(/[/?]/)); // Permet de trouver la racine des urls de type /face-v2/:id
            url = "/" + urlTokens[1];
        }

        switch (url) {
            case "/intermediate-search":
            case "/inspection-list-v2":
            case "/inspection-list-list":
                await this.navigateToMovement(navController, router, inspectionState);
                break;
            case "/mileage-entry":
            case "/movement-departure":
            case "/movement-return":
                if (inspectionState.inspection.checklists.length > 0) {
                    await this.navigateToChecklists(navController, inspectionState);
                } else if (inspectionState.inspection.faces.length > 0) {
                    await this.navigateToFaces(navController, inspectionState);
                } else if (!isNullOrEmpty(inspectionState.inspection.equipment.modelTyreWear)
                           && !isNullOrEmpty(inspectionState.inspection.equipment.modelBrakeWear)
                           && inspectionState.inspection.equipment.wear.length > 0) {
                    await this.navigateToEquipmentWear(navController, inspectionState);
                } else if (GENERAL_PHOTOS && inspectionState.customisation.generalPictures.length > 0) {
                    await this.navigateToGeneralPhotos(navController, inspectionState);
                } else {
                    await this.navigateToSummary(navController, inspectionState);
                }
                break;
            case "/checklist-v2":
                if (inspectionState.inspection.faces.length > 0) {
                    await this.navigateToFaces(navController, inspectionState);
                } else if (!isNullOrEmpty(inspectionState.inspection.equipment.modelTyreWear)
                           && !isNullOrEmpty(inspectionState.inspection.equipment.modelBrakeWear)
                           && inspectionState.inspection.equipment.wear.length > 0) {
                    await this.navigateToEquipmentWear(navController, inspectionState);
                } else if (GENERAL_PHOTOS && inspectionState.customisation.generalPictures.length > 0) {
                    await this.navigateToGeneralPhotos(navController, inspectionState);
                } else {
                    await this.navigateToSummary(navController, inspectionState);
                }
                break;
            case "/face-v2":
                if (!isNullOrEmpty(inspectionState.inspection.equipment.modelTyreWear)
                    && !isNullOrEmpty(inspectionState.inspection.equipment.modelBrakeWear)
                    && inspectionState.inspection.equipment.wear.length > 0) {
                    await this.navigateToEquipmentWear(navController, inspectionState);
                } else if (GENERAL_PHOTOS && inspectionState.customisation.generalPictures.length > 0) {
                    await this.navigateToGeneralPhotos(navController, inspectionState);
                } else {
                    await this.navigateToSummary(navController, inspectionState);
                }
                break;
            case "/equipment-tyres-wear":
                if (GENERAL_PHOTOS && inspectionState.customisation.generalPictures.length > 0) {
                    await this.navigateToGeneralPhotos(navController, inspectionState);
                } else {
                    await this.navigateToSummary(navController, inspectionState);
                }
                break;
            case "/face-general-photo-v2":
                await this.navigateToSummary(navController, inspectionState);
                break;
            default:
                console.error(this.constructor.name, "Route not found !", url);
        }
    }

    private static async navigateToChecklists(navController: NavController,
                                              inspectionState: InspectionState) {
        await navController.navigateForward("/checklist-v2", {
            state: {
                state: inspectionState,
            },
        });
    }

    private static async navigateToEquipmentWear(navController: NavController,
                                                 inspectionState: InspectionState) {
        await navController.navigateForward("equipment-tyres-wear", {
            state: {
                state: inspectionState,
            },
        });
    }

    private static async navigateToFaces(navController: NavController,
                                         inspectionState: InspectionState) {
        await navController.navigateForward("/face-v2/0", {
            state: {
                state: inspectionState,
            },
        });
    }

    private static async navigateToGeneralPhotos(navController: NavController,
                                                 inspectionState: InspectionState) {
        await navController.navigateForward("face-general-photo-v2", {
            state: {
                state: inspectionState,
            },
        });
    }

    private static async navigateToMovement(navController: NavController,
                                            router: Router,
                                            inspectionState: InspectionState) {
        if (inspectionState.inspection.kind == INSPECTION_KINDS.INTERMEDIATE) {
            if ((!inspectionState.inspection.equipment.counter1.id || !inspectionState.customisation.movement.counter1.visibility)
                && (!inspectionState.inspection.equipment.counter2.id || !inspectionState.customisation.movement.counter2.visibility)) {
                // Sauter "/mileage-entry" sans l'empiler dans la router stack
                await this.navigateToNextPage(navController, router, inspectionState, "/mileage-entry");
                return;
            }

            await navController.navigateForward("/mileage-entry", {
                state: {
                    state: inspectionState,
                },
            });
        } else if (inspectionState.inspection.kind == INSPECTION_KINDS.DEPARTURE) {
            await navController.navigateForward("/movement-departure", {
                state: {
                    state: inspectionState,
                },
            });
        } else {
            await navController.navigateForward("/movement-return", {
                state: {
                    state: inspectionState,
                },
            });
        }
    }

    private static async navigateToSummary(navController: NavController,
                                           inspectionState: InspectionState) {
        await navController.navigateForward("inspection-summary-v2", {
            state: {
                state: inspectionState,
            },
        });
    }
}
