import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { IonContent, IonRow, ModalController, Platform, ViewDidEnter, ViewWillEnter, ViewWillLeave } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Angular2SignaturePadComponent, Options } from "../../components/angular2-signaturepad/angular2-signaturepad.component";
import { DeviceHelper } from "../../helpers/device.helper";
import { PICTURE_QUALITY } from "../../interfaces/constants";
import { CropModal } from "../crop/crop.modal";

@Component({
    templateUrl: "draw.modal.html",
    styleUrls: ["draw.modal.scss"],
})
export class DrawModal implements ViewWillEnter, ViewDidEnter, ViewWillLeave {
    @ViewChild("content", { static: true }) content: IonContent;
    @ViewChild("containerRow", { static: true }) containerRow: IonRow;
    @ViewChild("container", { static: true }) container: ElementRef;
    @ViewChild("signaturePad", { static: true }) signaturePad: Angular2SignaturePadComponent;
    @ViewChild("backgroundCanvas", { static: true }) backgroundCanvas: ElementRef;

    @Input() imageData: string = "";
    @Input() exportQuality: number = 80;

    blueColor = "#2196F3";
    greenColor = "#4CAF50";
    redColor = "#FF2617";
    yellowColor = "#F0FF17";

    smallSize = 2;
    mediumSize = 4;
    largeSize = 8;

    drawSize: number = 0;
    drawColor: string = "";

    subscription: Subscription;

    constructor(private modalController: ModalController,
                private platform: Platform,
                private deviceHelper: DeviceHelper) {
    }

    ionViewDidEnter(): void {
        // Pour pouvoir mettre des ion-fab-list dans la toolbar
        const toolsToolbar = document.getElementById("toolsToolbar");
        if (toolsToolbar) {
            toolsToolbar.style.contain = "none";

            const toolsToolbarRoot = toolsToolbar.shadowRoot;
            const toolbarContainer = toolsToolbarRoot.querySelector(".toolbar-container");
            if (toolbarContainer) {
                (toolbarContainer as HTMLElement).style.contain = "none";
                (toolbarContainer as HTMLElement).style.overflow = "visible";
            }
        }
    }

    public ionViewWillEnter() {
        if (this.deviceHelper.isRunningOnDevice()) {
            this.subscription = this.platform.backButton.subscribeWithPriority(0, () => {
                this.dismiss();
            });
        }

        this.loadImage();
    }

    public ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public clear() {
        this.signaturePad.clear();
    }

    public save() {
        let base64 = this.signaturePad.toDataURL("image/png", 1);

        // Merger le dessin avec le background
        let image = new Image();
        image.src = base64;
        image.onload = () => {
            let canvas = this.backgroundCanvas.nativeElement as HTMLCanvasElement;
            let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
            base64 = canvas.toDataURL("image/jpeg", this.exportQuality > 1 ? this.exportQuality / 100 : this.exportQuality);

            void this.modalController.dismiss({ base64: base64 });
        };
    }

    public dismiss() {
        void this.modalController.dismiss();
    }

    public setColor(color: string) {
        this.drawColor = color;
        this.signaturePad.set(Options.penColor, color);
    }

    public setDrawSize(size: number) {
        this.drawSize = size;
        this.signaturePad.set(Options.minWidth, size);
        this.signaturePad.set(Options.dotSize, size);
    }

    public getActiveColorName() {
        if (this.drawColor == this.blueColor) {
            return "blue";
        }
        if (this.drawColor == this.greenColor) {
            return "green";
        }
        if (this.drawColor == this.redColor) {
            return "red";
        }
        if (this.drawColor == this.yellowColor) {
            return "yellow";
        }
    }

    public getDrawSizeName() {
        if (this.drawSize == this.smallSize) {
            return "small";
        }
        if (this.drawSize == this.mediumSize) {
            return "";
        }
        if (this.drawSize == this.largeSize) {
            return "large";
        }
    }

    public async cropImage() {
        let modal = await this.modalController.create({
            component: CropModal,
            componentProps: { imageData: this.imageData, exportQuality: PICTURE_QUALITY },
            backdropDismiss: false,
        });

        void modal.onDidDismiss()
            .then(result => {
                if (result.data) {
                    this.imageData = result.data.base64;

                    this.loadImage();
                }
            });
        void modal.present();
    }

    private drawBackground(image: HTMLImageElement) {
        let canvas = this.backgroundCanvas.nativeElement;
        let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
    }

    private loadImage() {
        if (this.imageData.length > 0) {
            let image = new Image();
            image.src = this.imageData;
            image.onload = () => {
                this.setContainerSize(image);

                this.drawBackground(image);

                setTimeout(() => {
                    this.setColor(this.blueColor);
                    this.setDrawSize(this.mediumSize);

                    this.signaturePad.set(Options.canvasWidth, image.naturalWidth);
                    this.signaturePad.set(Options.canvasHeight, image.naturalHeight);
                    this.signaturePad.set(Options.backgroundColor, "rgba(255,255,255,0)");
                }, 100);
            };
        }
    }

    private setContainerSize(image: HTMLImageElement) {
        let contentWidth = this.containerRow["el"].clientWidth;
        let contentHeight = this.containerRow["el"].clientHeight;

        this.container.nativeElement.style.maxWidth = contentWidth + "px";
        this.container.nativeElement.style.maxHeight = contentHeight + "px";

        if (image.naturalWidth > contentWidth && image.naturalHeight > contentHeight) {
            if (image.naturalHeight > image.naturalWidth) {
                // Portrait
                let width: number = contentHeight / (image.naturalHeight / image.naturalWidth);
                this.container.nativeElement.style.width = width + "px";
                this.container.nativeElement.style.height = contentHeight + "px";
            } else {
                // Landscape
                let height: number = contentWidth / (image.naturalWidth / image.naturalHeight);
                this.container.nativeElement.style.width = contentWidth + "px";
                this.container.nativeElement.style.height = height + "px";
            }
        } else {
            this.container.nativeElement.style.width = image.naturalWidth + "px";
            this.container.nativeElement.style.height = image.naturalHeight + "px";
        }
    }
}
