import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "irium-title",
    templateUrl: "./irium-title.component.html",
    styleUrls: ["./irium-title.component.scss"],
})
export class IriumTitleComponent {
    @Input() subtitle: string = "";
    @Input() icon: string = "";
    @Input() additionalActionIcon: string = "";
    @Input() mandatory: boolean = false;
    @Input() line: boolean = true;
    @Input() smaller: boolean = false;

    @Output() additionalActionClick: EventEmitter<any> = new EventEmitter<any>();

    public onAdditionalActionClick() {
        if (this.additionalActionClick) {
            this.additionalActionClick.emit();
        }
    }
}
