import { Injectable } from "@angular/core";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { KeyValue } from "../../gyzmo-commons/models/keyValue.model";
import { CustomisationWsDao } from "../dao/ws/customisation.ws.dao";
import { CustomisationDto } from "../dto/customisation.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class CustomisationService {
    public static readonly KEY = "CUSTOMISATION";

    constructor(private customisationWsDao: CustomisationWsDao,
                private keyValueDbDao: KeyValueDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async synchronizeCustomisation(companyId: string) {
        let customisation = await this.customisationWsDao.get(this.serversConnectionsProvider.getPrimaryServerConnection(), companyId)
            .catch(() => {
                return new CustomisationDto();
            });

        await this.keyValueDbDao.save(new KeyValue(CustomisationService.KEY, JSON.stringify(customisation)));
    }

    public async getCustomisation(): Promise<CustomisationDto> {
        let customisation = await this.keyValueDbDao.get(CustomisationService.KEY);
        if (!customisation) {
            return new CustomisationDto();
        }

        return JSON.parse(customisation.value) as CustomisationDto;
    }
}
