import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { GestureController } from "@ionic/angular";
import { Gesture, GestureDetail } from "@ionic/core";

@Directive({
    selector: "[horizontalSwipeEvent]",
})
export class HorizontalSwipeEventDirective implements OnInit, OnDestroy {
    pressGesture: Gesture;

    @Input() swipeThreshold: number = 80;
    @Output() swiped: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef,
                private gestureCtrl: GestureController) {
    }

    ngOnInit() {
        this.pressGesture = this.gestureCtrl.create({
            el: this.elementRef.nativeElement,
            gestureName: "horizontalSwipeEvent",
            gesturePriority: 100,
            threshold: 0,
            direction: "x",
            passive: true,
            onEnd: (detail: GestureDetail) => {
                if (Math.abs(detail.deltaY) < 40
                    && Math.abs(detail.deltaX) >= this.swipeThreshold) {
                    this.swiped.emit(detail);
                }
            },
        });

        this.pressGesture.enable(true);
    }

    ngOnDestroy() {
        this.pressGesture.destroy();
    }
}
