import { DamageCategory } from "../../../models/inspection/common/damageCategory.model";
import { BaseDto } from "../../base/base.dto";
import { DamageValueDto } from "./damageValue.dto";

export class DamageCategoryDto extends BaseDto<DamageCategory> {
    public id: string = "";
    public wording: string = "";

    // volatile in v2
    public possibleValues: DamageValueDto[] = [];

    static fromBody(body: any): DamageCategoryDto {
        let damageCategoryDto = new DamageCategoryDto();

        damageCategoryDto.id = "" + body.id;
        damageCategoryDto.wording = body.wording;

        if (body.possibleValues) {
            body.possibleValues.forEach(possibleValue => {
                damageCategoryDto.possibleValues.push(DamageValueDto.fromBody(possibleValue, damageCategoryDto.id));
            });
        }

        return damageCategoryDto;
    }

    static fromModel(damageCategory: DamageCategory) {
        let damageCategoryDto = new DamageCategoryDto();

        damageCategoryDto.id = damageCategory.id;
        damageCategoryDto.wording = damageCategory.wording;

        if (damageCategory.possibleValues) {
            damageCategory.possibleValues.forEach(possibleValue => {
                damageCategoryDto.possibleValues.push(DamageValueDto.fromModel(possibleValue));
            });
        }

        return damageCategoryDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): DamageCategory {
        let damageCategory = new DamageCategory();

        damageCategory.id = this.id;
        damageCategory.wording = this.wording;

        if (this.possibleValues) {
            this.possibleValues.forEach(possibleValue => {
                damageCategory.possibleValues.push(possibleValue.toModel());
            });
        }

        return damageCategory;
    }
}
