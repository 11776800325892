import { DatabaseLocation } from "./databaseLocation";

export class SQLiteResult {
    constructor(public success: boolean,
                public rows: any[],
                public error?: string,
                public insertId?: number) {
    }
}

export abstract class SqlProvider {
    abstract close(): Promise<boolean>;

    abstract commitBulk(): Promise<void>;

    abstract disableBulkWriting(): void;

    abstract dropDatabase(): Promise<void>;

    abstract enableBulkWriting(): void;

    abstract getDbName(): string;

    abstract getExistingTables(): Promise<string[]>;

    abstract initialize(dbName: string,
                        location: DatabaseLocation): Promise<boolean>;

    abstract isInitialized(): boolean;

    abstract query(queryText: string, bulkable?: boolean): Promise<SQLiteResult>;
}

