import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { ImageCropperModule } from "ngx-image-cropper";
import { Angular2SignaturePadModule } from "../../components/angular2-signaturepad/angular2-signaturepad.module";
import { GyzmoButtonModule } from "../../components/gyzmo-button/gyzmo-button.module";
import { CropModal } from "./crop.modal";

@NgModule(
    {
        declarations: [
            CropModal,
        ],
        imports: [
            CommonModule,
            FormsModule,
            IonicModule,
            TranslateModule,
            GyzmoButtonModule,
            Angular2SignaturePadModule,
            ImageCropperModule,
        ],
        providers: [],
    })

export class CropModalModule {
}
