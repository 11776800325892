import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";

export class DamageValue extends ModelBase {
    static readonly TABLENAME = "DamageValues";

    public id: string = "";
    public categoryId: string = "";
    public wording: string = "";
    public order: string = "";

    constructor() {
        super(DamageValue.TABLENAME);
    }
}
