import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { GestureController } from "@ionic/angular";
import { Gesture, GestureDetail } from "@ionic/core";

@Directive({
    selector: "[gestureEvents]",
})
export class GestureEventsDirective implements OnInit, OnDestroy {
    pressGesture: Gesture;
    lastGestureDetail: GestureDetail;
    pressed: boolean = false;
    pannedFired: boolean = false;
    timerId: any = 0;

    @Input() longPressDelay: number = 400;
    @Output() longPressed: EventEmitter<any> = new EventEmitter();
    @Input() pannedThreshold: number = 120;
    @Output() panned: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef,
                private gestureCtrl: GestureController) {
    }

    ngOnInit() {
        this.pressGesture = this.gestureCtrl.create({
            el: this.elementRef.nativeElement,
            gestureName: "gestureDetection",
            gesturePriority: 100,
            threshold: 0,
            direction: "y",
            passive: true,
            onStart: (detail: GestureDetail) => {
                this.lastGestureDetail = detail;

                this.pressed = false;
                this.timerId = setTimeout(() => {
                        if (Math.abs(this.lastGestureDetail.deltaX) < 10 && Math.abs(this.lastGestureDetail.deltaY) < 10) {
                            this.longPressed.emit(detail);
                            this.pressed = true;
                        }
                    },
                    this.longPressDelay);
            },
            onMove: (detail: GestureDetail) => {
                this.lastGestureDetail = detail;

                if (!this.pannedFired
                    && Math.abs(detail.deltaY) >= this.pannedThreshold) {
                    this.pannedFired = true;
                    this.panned.emit(detail);
                }
            },
            onEnd: (detail: GestureDetail) => {
                this.lastGestureDetail = detail;
                this.pannedFired = false;

                clearTimeout(this.timerId);
            },
        });

        this.pressGesture.enable(true);
    }

    ngOnDestroy() {
        this.pressGesture.destroy();
    }
}
