import { Injectable } from "@angular/core";
import { LoggerService } from "../../../../gyzmo-commons/services/logs/logger.service";
import { DamageCategoryDbDao } from "../../../dao/db/inspection/common/damageCategory.db.dao";
import { DamageCategoryWsDao } from "../../../dao/ws/inspection/common/damageCategory.ws.dao";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class DamageCategoryService {
    constructor(private logger: LoggerService,
                private damageCategoryWsDao: DamageCategoryWsDao,
                private damageCategoryDbDao: DamageCategoryDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public synchronize(): Promise<void> {
        return this.damageCategoryWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(damageCategoryDtos => {
                let promises = [];

                damageCategoryDtos.forEach(damageCategoryDto => {
                    promises.push(this.damageCategoryDbDao.save(damageCategoryDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }

    public async checkDamageCategoryData(): Promise<boolean> {
        let correctness = true;
        let list = await this.damageCategoryDbDao.getList(true);

        if (list == null || list.length <= 0) {
            correctness = false;
            this.logger.error(this.constructor.name, "No damage categories !");
        }

        return correctness;
    }

    public async checkPossibleValuesData(): Promise<boolean> {
        let correctness = true;
        let list = await this.damageCategoryDbDao.getList(true);
        list.forEach(value => {
            if (value.possibleValues == null || value.possibleValues.length <= 0) {
                correctness = false;
                this.logger.error(this.constructor.name, value.id + " has no possible values !");
            }
        });

        return correctness;
    }
}
