import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { INSPECTION_SAVE_GENERATE_DAMAGE, INSPECTION_SAVE_UPDATE_MOVEMENT, INSPECTION_SAVE_UPDATE_STC } from "../../../../../app/app.constants";
import { environment } from "../../../../../environments/environment";
import { AssetsHelper } from "../../../../../gyzmo-commons/helpers/assets.helper";
import { isNullOrEmpty } from "../../../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../../../gyzmo-commons/http/header.constant";
import { DATE_NODEJS_FORMAT } from "../../../../../gyzmo-commons/interfaces/constants";
import { DateProvider } from "../../../../../gyzmo-commons/interfaces/dateProvider";
import { AttachmentDto } from "../../../../dto/attachment.dto";
import { EquipmentDto } from "../../../../dto/equipment.dto";
import { ChecklistDto } from "../../../../dto/inspection/v2/checklist.dto";
import { InspectionDto } from "../../../../dto/inspection/v2/inspection.dto";
import { ZoneElementDto } from "../../../../dto/inspection/v2/zoneElement.dto";
import { HttpErrorHandler } from "../../../../http/httpErrorHandler";
import { ServerConnection } from "../../../../http/serverConnection";
import { WsDao } from "../../../../http/wsDao";
import { AttachmentKinds } from "../../../../interfaces/attachmentKinds";
import { ZoneElementDbDaoV2 } from "../../../db/inspection/v2/zoneElement.db.dao";
import { AttachmentWsDao } from "../../attachment.ws.dao";
import { EquipmentWsDao } from "../../equipment.ws.dao";

@Injectable({
    providedIn: "root",
})
export class InspectionWsDaoV2 extends WsDao<InspectionDto> {
    static readonly WS = "inspections";

    constructor(private httpErrorHandler: HttpErrorHandler,
                private equipmentWsDao: EquipmentWsDao,
                private attachmentWsDao: AttachmentWsDao,
                private zoneElementDbDaoV2: ZoneElementDbDaoV2,
                private dateProvider: DateProvider,
                private assetsHelper: AssetsHelper) {
        super();
    }

    public async getById(serverConnection: ServerConnection, id: string, downloadChecklistAttachment: boolean = false): Promise<InspectionDto> {
        return new Promise<InspectionDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.setApiVersion(2);

            serverConnection.get(this.constructor.name, InspectionWsDaoV2.WS + "/:id", tokens)
                .then(response => {
                    let inspectionDto = InspectionDto.fromBody(response.body, serverConnection.getServerDto());
                    let promises = [];

                    response.body.faces.forEach(face => {
                        face.zones.forEach(zone => {
                            zone.elements.forEach(element => {
                                let zoneElementDto = ZoneElementDto.fromBody(element, zone.id);
                                promises.push(this.zoneElementDbDaoV2.save(zoneElementDto.toModel()));
                            });
                        });
                    });

                    if (!isNullOrEmpty(inspectionDto.equipment.id)) {
                        promises.push(this.equipmentWsDao.getById(serverConnection, inspectionDto.equipment.id)
                            .then((equipmentDto: EquipmentDto) => {
                                inspectionDto.equipment = equipmentDto;
                            }));
                    }

                    if (downloadChecklistAttachment) {
                        inspectionDto.checklists.forEach((checklist: ChecklistDto) => {
                            checklist.elements.forEach(element => {
                                promises.push(this.attachmentWsDao.getByChecklistId(serverConnection, element.id, AttachmentKinds.PHOTO)
                                    .then((attachmentDtos: AttachmentDto[]) => {
                                        attachmentDtos.forEach(attachmentDto => {
                                            element.attachments.push(attachmentDto);
                                        });
                                    }));
                            });
                        });
                    }

                    Promise.all(promises)
                        .then(() => {
                            resolve(inspectionDto);
                        });
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject(reason);
                    }
                });
        });
    }

    public save(serverConnection: ServerConnection, inspection: InspectionDto): Promise<InspectionDto> {
        return new Promise<InspectionDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", inspection.id);

            serverConnection.setApiVersion(2);

            inspection.faces.forEach(face => {
                face.zones.forEach(zone => {
                    zone.damages.forEach(damage => {
                        if (damage.isDeactivate) {
                            void this.deactivateDamage(serverConnection, inspection.id, damage.id);
                        }
                    });
                });
            });

            serverConnection.put(this.constructor.name,
                InspectionWsDaoV2.WS + "/:id?updateShortTermContract=" + INSPECTION_SAVE_UPDATE_STC
                + "&generateDamage=" + INSPECTION_SAVE_GENERATE_DAMAGE
                + "&updateMovement=" + INSPECTION_SAVE_UPDATE_MOVEMENT,
                tokens,
                inspection.toBody(this.dateProvider, this.assetsHelper))
                .then(response => {
                    resolve(inspection);
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject(reason);
                    }
                });
        });
    }

    public getList(serverConnection: ServerConnection, startDate?: DateTime, endDate?: DateTime): Promise<InspectionDto[]> {
        return new Promise<InspectionDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = InspectionWsDaoV2.WS + "/?onlyHeaders=1";

            if (startDate && endDate) {
                url += "&_limit=NOLIMIT&startDate=" + startDate.toFormat(DATE_NODEJS_FORMAT) + "&endDate=" + endDate.toFormat(DATE_NODEJS_FORMAT);
            }

            serverConnection.setApiVersion(2);

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    let inspectionDtos: InspectionDto[] = [];

                    if (response.body instanceof Array) {
                        response.body.forEach(value => {
                            let inspectionDto = InspectionDto.fromBody(value, serverConnection.getServerDto());
                            inspectionDtos.push(inspectionDto);
                        });
                    }

                    resolve(inspectionDtos);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public deactivateDamage(serverConnection: ServerConnection, id: string, damageId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {

            let tokens = new Map<string, string>();
            tokens.set("id", id);
            tokens.set("damageId", damageId);

            serverConnection.delete(this.constructor.name, InspectionWsDaoV2.WS + "/:id/damages/:damageId", tokens)
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public initializeByEquipmentId(serverConnection: ServerConnection, equipmentId: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let tokens = new Map<string, string>();

            let body = {
                relativeKey: equipmentId,
                field: "F090KY",
                object: {
                    id: "F090PARC",
                },
                kind: {
                    id: "K575TI8OPE-ETA",
                },
            };

            let url = InspectionWsDaoV2.WS;
            if (environment.mocked) {
                url += "?kind=intermediate&equipmentId=" + equipmentId;
            }

            serverConnection.setApiVersion(1);

            serverConnection.post(this.constructor.name, url, tokens, body)
                .then(response => {
                    let groupId = response.headers.get(XVEGAID.toLowerCase());
                    resolve(groupId);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public initializeDepartureByMovementId(serverConnection: ServerConnection, movementId: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let tokens = new Map<string, string>();

            let body = {
                relativeKey: movementId,
                field: "F570KY",
                object: {
                    id: "F570MVT",
                },
                kind: {
                    id: "K575TI8OPE-DEP",
                },
            };

            let url = InspectionWsDaoV2.WS;
            if (environment.mocked) {
                url += "?kind=departure&movementId=" + movementId;
            }

            serverConnection.setApiVersion(1);

            serverConnection.post(this.constructor.name, url, tokens, body)
                .then(response => {
                    let groupId = response.headers.get(XVEGAID.toLowerCase());
                    resolve(groupId);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public initializeReturnByMovementId(serverConnection: ServerConnection, movementId: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let tokens = new Map<string, string>();

            let body = {
                relativeKey: movementId,
                field: "F570KY",
                object: {
                    id: "F570MVT",
                },
                kind: {
                    id: "K575TI8OPE-ARR",
                },
            };

            let url = InspectionWsDaoV2.WS;
            if (environment.mocked) {
                url += "?kind=return&movementId=" + movementId;
            }

            serverConnection.setApiVersion(1);

            serverConnection.post(this.constructor.name, url, tokens, body)
                .then(response => {
                    let groupId = response.headers.get(XVEGAID.toLowerCase());
                    resolve(groupId);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getCountBetweenDates(serverConnection: ServerConnection,
                                startDate: DateTime,
                                endDate: DateTime): Promise<{ date: DateTime, departureCount: number, returnCount: number, intermediateCount: number }[]> {
        return new Promise<{ date: DateTime, departureCount: number, returnCount: number, intermediateCount: number }[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = "inspection-list";

            if (startDate && endDate) {
                url += "?_limit=NOLIMIT&startDate=" + startDate.toFormat(DATE_NODEJS_FORMAT) + "&endDate=" + endDate.toFormat(DATE_NODEJS_FORMAT);
            }

            serverConnection.setApiVersion(2);

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    let result: { date: DateTime, departureCount: number, returnCount: number, intermediateCount: number }[] = [];

                    if (response.body instanceof Array) {
                        response.body.forEach(element => {
                            let counts: any = {};
                            counts.date = DateTime.fromISO(element.date);
                            counts.departureCount = element.departureCount;
                            counts.returnCount = element.returnCount;
                            counts.intermediateCount = element.intermediateCount;

                            result.push(counts);
                        });
                    }

                    resolve(result);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public async delete(serverConnection: ServerConnection,
                        id: string) {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.delete(this.constructor.name, InspectionWsDaoV2.WS + "/:id", tokens)
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
