import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DateHelper } from "../helpers/date.helper";
import { DateProvider } from "../interfaces/dateProvider";
import { IServerConnection } from "../interfaces/IServerConnection";

@Injectable({
    providedIn: "root",
})
export class DateWs {
    static readonly WS = "technical/date";

    constructor(private dateProvider: DateProvider) {
    }

    public getDate(serverConnection: IServerConnection): Promise<DateTime> {
        return new Promise<DateTime>((resolve, reject) => {
            let tokens = new Map<string, string>();
            serverConnection.get(this.constructor.name, DateWs.WS, tokens, false, false, true)
                .then(response => {
                    let date = this.responseBodyToModel(response.body);

                    if (!date.isValid) {
                        resolve(this.dateProvider.now());
                    } else {
                        resolve(date);
                    }
                })
                .catch(reason => {
                    resolve(this.dateProvider.now());
                });
        });
    }

    responseBodyToModel(body: any): DateTime {
        const utcDate = body.utcDate;

        return DateHelper.tryFromFormat(utcDate, "yyyy-MM-dd'T'HH:mm:ss").toUTC();
    }
}
