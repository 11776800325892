import { FtRecord } from "../models/frRecord.model";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";

export class FtRecordDto extends BaseDto<FtRecord> {
    public id: string = "";
    public isActive: boolean = true;
    public object: string = "";
    public wording: string = "";

    public static fromBody(body: any, object: string): FtRecordDto {
        let ftRecordDto = new FtRecordDto();

        ftRecordDto.id = "" + body.id;
        ftRecordDto.object = object;
        ftRecordDto.isActive = body.isActive;
        ftRecordDto.wording = body.wording;

        return ftRecordDto;
    }

    public static fromModel(ftRecord: FtRecord): FtRecordDto {
        let ftRecordDto = new FtRecordDto();

        ftRecordDto.id = ftRecord.id;
        ftRecordDto.object = ftRecord.object;
        ftRecordDto.isActive = ftRecord.isActive == 1;
        ftRecordDto.wording = ftRecord.wording;

        return ftRecordDto;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): FtRecord {
        let ftRecord = new FtRecord();

        ftRecord.id = this.id;
        ftRecord.object = this.object;
        ftRecord.isActive = this.isActive ? 1 : 0;
        ftRecord.wording = this.wording;

        return ftRecord;
    }
}
