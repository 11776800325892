// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dragWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

#drag {
  touch-action: auto;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/modals/view/view.modal.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,sBAAA;AACJ","sourcesContent":["#dragWrapper {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n#drag {\n    touch-action: auto;\n    position: absolute;\n    left: 0;\n    top: 0;\n    background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
