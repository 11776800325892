import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { LoadingHelper } from "../../gyzmo-commons/helpers/loading.helper";
import { ToastHelper } from "../../gyzmo-commons/helpers/toast.helper";
import { LoggerService } from "../../gyzmo-commons/services/logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class HttpErrorHandler {
    toastDisplayed = false;

    private userDisconnectedSubject = new EventEmitter<void>();

    constructor(private logger: LoggerService,
                private loadingHelper: LoadingHelper,
                private toastHelper: ToastHelper) {
    }

    public getUserDisconnectedObservable(): EventEmitter<void> {
        return this.userDisconnectedSubject;
    }

    public publishUserDisconnected() {
        this.userDisconnectedSubject.emit();
    }

    public handleError(error: HttpErrorResponse, showErrorToast = true): boolean {
        let handled = false;
        void this.loadingHelper.hideAll();

        switch (error.status) {
            case 401:
            case 403:
                handled = true;
                this.logger.error(this.constructor.name, JSON.stringify(error));
                this.publishUserDisconnected();
                break;
            case 404:
            case 500:
            case 502:
                this.logger.error(this.constructor.name, JSON.stringify(error));
                if (!this.toastDisplayed && showErrorToast) {
                    if (error["message"]) {
                        this.toastDisplayed = true;
                        void this.toastHelper.show(
                            error["message"],
                            true,
                            () => {
                                this.toastDisplayed = false;
                            });
                    } else {
                        this.toastDisplayed = true;
                        void this.toastHelper.show(
                            error.statusText + " at " + error.url,
                            true,
                            () => {
                                this.toastDisplayed = false;
                            });
                    }
                }
                break;
        }

        return handled;
    }
}
