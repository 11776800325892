import { Injectable } from "@angular/core";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { FileOpener } from "@capawesome-team/capacitor-file-opener";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import { DeviceHelper, PlatformName } from "../helpers/device.helper";
import { FileHelper } from "../helpers/file.helper";
import { LoadingHelper } from "../helpers/loading.helper";
import { LoggerService } from "./logs/logger.service";

declare const cordova: any;
declare const window;

@Injectable({
    providedIn: "root",
})
export class FileService {

    constructor(private logger: LoggerService,
                private file: File,
                private loadingHelper: LoadingHelper,
                private deviceHelper: DeviceHelper) {
    }

    public getBaseDirectory() {
        switch (this.deviceHelper.getPlatform()) {
            case PlatformName.IOS:
                return (cordova.file) ? cordova.file.documentsDirectory : "";
            case PlatformName.ANDROID:
                return (cordova.file) ? cordova.file.externalDataDirectory : "";
        }
    }

    public async saveToTempFile(fileContentBase64: string): Promise<{ filename: string, path: string }> {
        if (this.deviceHelper.isRunningOnDevice()) {
            let filename = "temp" + FileHelper.getExtension(fileContentBase64);

            await this.file.writeFile(this.getBaseDirectory(), filename, this.base64ToBlob(fileContentBase64), { replace: true })
                .catch((reason) => {
                    this.logger.error(this.constructor.name, "Error writing file (" + reason + ")");
                });
            return { path: this.getBaseDirectory(), filename: filename };
        } else {
            throw new Error("Not implemented on web !");
        }
    }

    public async openDocument(fileContentBase64: string) {
        if (this.deviceHelper.isRunningOnDevice()) {
            await this.loadingHelper.showLoading();

            let tempFile = await this.saveToTempFile(fileContentBase64);

            await this.file.writeFile(tempFile.path, tempFile.filename, this.base64ToBlob(fileContentBase64), { replace: true })
                .catch((reason) => {
                    this.logger.error(this.constructor.name, "Error writing file (" + reason + ")");
                });
            await FileOpener.openFile({
                path: tempFile.path + tempFile.filename,
                mimeType: FileHelper.extractMimeType(fileContentBase64),
            }).catch((reason) => {
                this.logger.error(this.constructor.name, "Error opening file (" + reason + ")");
            });
            await this.loadingHelper.hideAll();
        } else {
            let newWindow = window.open("");
            newWindow.document.write("<body style='margin:0;overflow:hidden'><iframe width='100%' height='100%' src='" + fileContentBase64 + "'></iframe></body>");
            newWindow.document.close();
        }
    }

    public async pickAndReadDocument(): Promise<string> {
        const result = await FilePicker.pickFiles({
            types: ["application/pdf",
                    "text/plain",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "text/csv",
                    "text/comma-separated-values",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
            limit: 1,
            readData: true,
        });

        if (result.files && result.files.length > 0) {
            const file = result.files[0];
            if (file.data) {
                return FileHelper.addDataUrlHeaderToBase64(file.data, file.mimeType);
            }
        }

        return null;
    }

    public readFile(path: string, filename: string) {
        return this.file.readAsDataURL(path, filename);
    }

    private base64ToBlob(b64Data): Blob {
        const sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, "");
        b64Data = b64Data.replace(/\s/g, "");
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays);
    }
}
