import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { NavController } from "@ionic/angular";
import { InspectionRouter } from "../../app/inspection/inspection.router";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { LoadingHelper } from "../../gyzmo-commons/helpers/loading.helper";
import { ToastHelper } from "../../gyzmo-commons/helpers/toast.helper";
import { LoggerService } from "../../gyzmo-commons/services/logs/logger.service";
import { INSPECTION_KINDS } from "../interfaces/INSPECTION_KINDS";
import { InspectionState } from "../interfaces/inspectionState";
import { CustomisationService } from "./customisation.service";
import { InspectionServiceV2 } from "./inspection/v2/inspection.service";
import { MaintenanceControlsService } from "./maintenanceControls.service";

@Injectable({
    providedIn: "root",
})
export class DeepLinkService {
    constructor(private zone: NgZone,
                private navController: NavController,
                private logger: LoggerService,
                private inspectionServiceV2: InspectionServiceV2,
                private maintenanceControlsService: MaintenanceControlsService,
                private customisationService: CustomisationService,
                private router: Router,
                private loadingHelper: LoadingHelper,
                private toastHelper: ToastHelper,
                private deviceHelper: DeviceHelper) {
    }

    public async initialize() {
        if (this.deviceHelper.isRunningOnDevice()) {
            await App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
                void this.zone.run(async () => {
                    try {
                        await this.loadingHelper.showLoading();
                        let args = event.url.split("//")[1];

                        let inspection = await this.inspectionServiceV2.synchronizeInspection(args);
                        if (!inspection) {
                            await this.toastHelper.show("Inspection non trouvée.");
                        } else {
                            let inspectionState = new InspectionState(inspection,
                                await this.maintenanceControlsService.listForEquipmentOffline(inspection.equipment.id),
                                await this.customisationService.getCustomisation());

                            if (inspection.kind == INSPECTION_KINDS.INTERMEDIATE) {
                                if ((!inspection.equipment.counter1.id || !inspectionState.customisation.movement.counter1.visibility)
                                    && (!inspection.equipment.counter2.id || !inspectionState.customisation.movement.counter2.visibility)) {
                                    // Sauter "/mileage-entry" sans l'empiler dans la router stack
                                    await InspectionRouter.navigateToNextPage(this.navController, this.router, inspectionState, "/mileage-entry");
                                    return;
                                }

                                await this.navController.navigateForward("/mileage-entry", {
                                    state: {
                                        state: inspectionState,
                                    },
                                });
                            } else if (inspection.kind == INSPECTION_KINDS.DEPARTURE) {
                                await this.navController.navigateForward("/movement-departure", {
                                    state: {
                                        state: inspectionState,
                                    },
                                });
                            } else {
                                await this.navController.navigateForward("/movement-return", {
                                    state: {
                                        state: inspectionState,
                                    },
                                });
                            }
                        }
                        await this.loadingHelper.hideAll();
                    } catch (e) {
                        this.logger.error(this.constructor.name, e);
                        await this.toastHelper.show("Une erreur est survenu dans la récupération de l'inspection.");
                        await this.loadingHelper.hideAll();
                    }
                });
            });
        }
    }
}
