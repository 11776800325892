import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PictureHelper } from "./picture.helper";

@Injectable({
    providedIn: "root",
})
export class AssetsHelper {

    constructor(private http: HttpClient) {
    }

    public async exists(filename: string): Promise<boolean> {
        let response = await firstValueFrom(this.http.get("../../assets/" + filename, {
            responseType: "arraybuffer",
        })).catch(() => {
            return null;
        });

        return response != null;
    }

    public readFile(filename: string): Promise<any> {
        return firstValueFrom(this.http.get<string>("../../assets/" + filename));
    }

    public readBinaryFile(filename: string): Promise<ArrayBuffer> {
        return firstValueFrom(this.http.get("../../assets/" + filename, {
            responseType: "arraybuffer",
        }));
    }

    public readFileAsText(filename: string): Promise<string> {
        return firstValueFrom(this.http.get("../../assets/" + filename, {
            responseType: "text",
        }));
    }

    public async readImageAsBase64(filename: string): Promise<string> {
        let buffer = await firstValueFrom(this.http.get("../../assets/" + filename, {
            responseType: "arraybuffer",
        }));
        return PictureHelper.encodeToBase64(buffer);
    }
}
