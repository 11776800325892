import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Sector extends ModelBase {
    static readonly TABLENAME = "Sectors";

    public id: string = "";
    public wording: string = "";

    constructor() {
        super(Sector.TABLENAME);
    }
}
