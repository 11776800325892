import { DateTime } from "luxon";
import { DateProvider } from "../interfaces/dateProvider";

export class RealDateProvider extends DateProvider {
    public now(): DateTime {
        return DateTime.now();
    }

    public nowAsDate(): Date {
        return new Date();
    }
}
