import { Zone } from "../../../models/inspection/v2/zone.model";
import { BaseDto } from "../../base/base.dto";
import { DamageDto } from "./damage.dto";

export class ZoneDto extends BaseDto<Zone> {
    public id: string = "";
    public faceId: string = "";
    public shapeType: string = "";
    public coordinates: string = "";
    public zoneClick: string = "";

    public damages: DamageDto[] = [];

    static fromBody(body: any, inspectionId: string, faceId: string): ZoneDto {
        let zoneDto = new ZoneDto();

        zoneDto.id = "" + body.id;
        zoneDto.faceId = faceId;
        zoneDto.shapeType = body.shapeType;
        zoneDto.coordinates = body.coordinates;
        zoneDto.zoneClick = body.zoneClick;

        if (body.damages) {
            body.damages.forEach(damage => {
                zoneDto.damages.push(DamageDto.fromBody(damage, inspectionId, zoneDto.id));
            });
        }

        return zoneDto;
    }

    static fromModel(zone: Zone) {
        let zoneDto = new ZoneDto();

        zoneDto.id = zone.id;
        zoneDto.faceId = zone.faceId;
        zoneDto.shapeType = zone.shapeType;
        zoneDto.coordinates = zone.coordinates;
        zoneDto.zoneClick = zone.zoneClick;

        if (zone.damages) {
            zone.damages.forEach(damage => {
                zoneDto.damages.push(DamageDto.fromModel(damage));
            });
        }

        return zoneDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.damages = [];
        this.damages.forEach(damage => {
            clone.damages.push(damage.toBody());
        });

        // Enlever les anciens dommages avant envoi
        clone.damages = clone.damages.filter(damage => {
            return !damage.isOld;
        });

        delete clone.coordinates;
        delete clone.shapeType;

        return clone;
    }

    public toModel(): Zone {
        let zone = new Zone();

        zone.id = this.id;
        zone.faceId = this.faceId;
        zone.shapeType = this.shapeType;
        zone.coordinates = this.coordinates;
        zone.zoneClick = this.zoneClick;

        if (this.damages) {
            this.damages.forEach(damage => {
                zone.damages.push(damage.toModel());
            });
        }

        return zone;
    }
}
