import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Movement } from "../../models/movement.model";
import { LocationDbDao } from "./location.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";
import { UserDbDao } from "./user.db.dao";

@Injectable({
    providedIn: "root",
})
export class MovementDbDao extends DbDaoBase<Movement> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private userDbDao: UserDbDao,
        private locationDbDao: LocationDbDao,
        private thirdPartyDbDao: ThirdPartyDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Movement.TABLENAME + "_id"
                    + " ON " + Movement.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Movement.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "documentNumber TEXT, "
                    + "contractNumber TEXT, "
                    + "startDate DATE, "
                    + "startFuel NUMERIC, "
                    + "startFuel2 NUMERIC, "
                    + "startMileage NUMERIC, "
                    + "startMileage2 NUMERIC, "
                    + "returnDate DATE, "
                    + "returnFuel NUMERIC, "
                    + "returnFuel2 NUMERIC, "
                    + "returnMileage NUMERIC, "
                    + "returnMileage2 NUMERIC, "
                    + "relatedTable TEXT, "
                    + "customer TEXT, "
                    + "driver TEXT, "
                    + "includingTaxAmount TEXT, "
                    + "startLocation TEXT, "
                    + "returnLocation TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Movement.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Movement.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Movement> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Movement.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                let movement: Movement = this.rowToModel(data.rows[0]);

                let hydratationPromises = [];

                if (hydrate) {
                    hydratationPromises.push(this.thirdPartyDbDao.get(movement.driver.id, hydrate)
                        .then(value => {
                            movement.driver = value;
                        }));

                    hydratationPromises.push(this.thirdPartyDbDao.get(movement.customer.id, hydrate)
                        .then(value => {
                            movement.customer = value;
                        }));

                    hydratationPromises.push(this.locationDbDao.get(movement.startLocation.id, hydrate)
                        .then(value => {
                            movement.startLocation = value;
                        }));

                    hydratationPromises.push(this.locationDbDao.get(movement.returnLocation.id, hydrate)
                        .then(value => {
                            movement.returnLocation = value;
                        }));
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return movement;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Movement.TABLENAME;
    }

    protected rowToModel(row: any): Movement {
        let movement = new Movement();

        movement.id = row.id;
        movement.documentNumber = row.documentNumber;
        movement.contractNumber = row.contractNumber;
        movement.startDate = row.startDate;
        movement.startFuel = row.startFuel;
        movement.startFuel2 = row.startFuel2;
        movement.startMileage = Number(row.startMileage);
        movement.startMileage2 = Number(row.startMileage2);
        movement.returnDate = row.returnDate;
        movement.returnFuel = row.returnFuel;
        movement.returnFuel2 = row.returnFuel2;
        movement.returnMileage = Number(row.returnMileage);
        movement.returnMileage2 = Number(row.returnMileage2);
        movement.includingTaxAmount = row.includingTaxAmount;

        movement.relatedTable = row.relatedTable;

        movement.customer.id = row.customer;
        movement.driver.id = row.driver;
        movement.startLocation.id = row.startLocation;
        movement.returnLocation.id = row.returnLocation;

        return movement;
    }

    public save(movement: Movement): Promise<Movement> {
        let promises = [];
        promises.push(this.userDbDao.getCurrentUserWithThirdPartyOnly()
            .then(value => {
                if (value.thirdParty.id != movement.customer.id) {
                    return this.thirdPartyDbDao.save(movement.customer);
                }
            }));
        promises.push(this.userDbDao.getCurrentUserWithThirdPartyOnly()
            .then(value => {
                if (value.thirdParty.id != movement.driver.id) {
                    return this.thirdPartyDbDao.save(movement.driver);
                }
            }));

        promises.push(this.locationDbDao.save(movement.startLocation));
        promises.push(this.locationDbDao.save(movement.returnLocation));

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Movement.TABLENAME + " (id, documentNumber, contractNumber, startDate, startFuel, startFuel2, startMileage, startMileage2, "
                            + "returnDate, returnFuel, returnFuel2, returnMileage, returnMileage2, relatedTable, customer, driver, includingTaxAmount, startLocation, returnLocation) VALUES ("
                            + this.getValue(movement.id)
                            + this.getValue(movement.documentNumber)
                            + this.getValue(movement.contractNumber)
                            + this.getValue(movement.startDate)
                            + this.getValue(movement.startFuel)
                            + this.getValue(movement.startFuel2)
                            + this.getValue(movement.startMileage)
                            + this.getValue(movement.startMileage2)
                            + this.getValue(movement.returnDate)
                            + this.getValue(movement.returnFuel)
                            + this.getValue(movement.returnFuel2)
                            + this.getValue(movement.returnMileage)
                            + this.getValue(movement.returnMileage2)
                            + this.getValue(movement.relatedTable)
                            + this.getFkValue(movement.customer)
                            + this.getFkValue(movement.driver)
                            + this.getValue(movement.includingTaxAmount)
                            + this.getFkValue(movement.startLocation)
                            + this.getFkValue(movement.returnLocation, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return movement;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
