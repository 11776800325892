import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class FtRecord extends ModelBase {
    static readonly TABLENAME = "FtRecords";

    public id: string = "";
    public isActive: number = 1;
    public object: string = "";
    public wording: string = "";

    constructor() {
        super(FtRecord.TABLENAME);
    }
}
