import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/notImplementedError";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { FtRecord } from "../../models/frRecord.model";

@Injectable({
    providedIn: "root",
})
export class FtRecordDbDao extends DbDaoBase<FtRecord> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + FtRecord.TABLENAME + "_id"
                    + " ON " + FtRecord.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + FtRecord.TABLENAME + "_object"
                + " ON " + FtRecord.TABLENAME + "(object);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + FtRecord.TABLENAME + "_isActive"
                + " ON " + FtRecord.TABLENAME + "(isActive);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + FtRecord.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "object TEXT,"
                    + "isActive NUMERIC,"
                    + "wording TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + FtRecord.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + FtRecord.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<FtRecord> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return FtRecord.TABLENAME;
    }

    protected rowToModel(row: any): FtRecord {
        let ftRecord = new FtRecord();

        ftRecord.id = row.id;
        ftRecord.isActive = row.isActive;
        ftRecord.object = row.object;
        ftRecord.wording = row.wording;

        return ftRecord;
    }

    public save(ftRecord: FtRecord): Promise<FtRecord> {
        let query = "INSERT OR REPLACE INTO " + FtRecord.TABLENAME + " (id, isActive, object, wording) VALUES ("
                    + this.getValue(ftRecord.id)
                    + this.getValue(ftRecord.isActive)
                    + this.getValue(ftRecord.object)
                    + this.getValue(ftRecord.wording, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return ftRecord;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public listByObject(object: string) {
        let selectQuery = "SELECT * FROM " + FtRecord.TABLENAME + " WHERE object='" + object + "' AND isActive=1;";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                let result: FtRecord[] = [];

                for (const row of data.rows) {
                    result.push(this.rowToModel(row));
                }

                return result;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
