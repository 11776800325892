import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    template: "",
})
export abstract class BasePage {
    protected constructor(protected router: Router,
                          protected route: ActivatedRoute) {
        // No need to unsubscribe this one, Router do it for us.
        this.route.queryParams.subscribe(() => {
            if (this.router.getCurrentNavigation()?.extras.state) {
                let state = this.router.getCurrentNavigation().extras.state;
                for (const field in state) {
                    this[field] = state[field];
                }

                this.onStateUpdated();
            }
        });
    }

    protected onStateUpdated() {
        // Override this to react to state updates
    }
}
