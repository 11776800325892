import { ModelBase } from "./base/model.base";

export class ThirdPartyType extends ModelBase {
    static readonly TABLENAME = "ThirdPartyTypes";
    public id: string = "";

    constructor() {
        super(ThirdPartyType.TABLENAME);
    }
}
