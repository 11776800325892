import { ChecklistElement } from "../../../models/inspection/v2/checklistElement.model";
import { AttachmentDto } from "../../attachment.dto";
import { BaseDto } from "../../base/base.dto";
import { DamageValueDto } from "../common/damageValue.dto";

export class ChecklistElementDto extends BaseDto<ChecklistElement> {
    public id: string = "";
    public inspectionId: string = "";
    public checklistId: string = "";
    public wording: string = "";
    public defaultValue: string = "";
    public savedValue: string = "";
    public param: string = "";
    public checklistModelId: string = "";
    public technicalLink: string = "";

    public attachments: AttachmentDto[] = [];

    // volatile
    public previousValue: string = "";
    public possibleValues: DamageValueDto[] = [];

    static fromBody(body: any, inspectionId: string, checklistId: string): ChecklistElementDto {
        let elementDto = new ChecklistElementDto();

        elementDto.id = "" + body.id;
        elementDto.inspectionId = inspectionId;
        elementDto.checklistId = checklistId;
        elementDto.wording = body.wording;
        elementDto.defaultValue = body.defaultValue;
        elementDto.previousValue = body.savedValue;
        elementDto.savedValue = body.savedValue;
        elementDto.checklistModelId = body.checklistModelId;
        elementDto.technicalLink = body.technicalLink;
        elementDto.param = body.param;

        if (body.attachments) {
            body.attachments.forEach(attachment => {
                elementDto.attachments.push(AttachmentDto.fromBody(attachment, "inspection", checklistId));
            });
        }

        return elementDto;
    }

    static fromModel(element: ChecklistElement) {
        let elementDto = new ChecklistElementDto();

        elementDto.id = element.id;
        elementDto.inspectionId = element.inspectionId;
        elementDto.checklistId = element.checklistId;
        elementDto.wording = element.wording;
        elementDto.defaultValue = element.defaultValue;
        elementDto.previousValue = element.savedValue;
        elementDto.savedValue = element.savedValue;
        elementDto.checklistModelId = element.checklistModelId;
        elementDto.technicalLink = element.technicalLink;
        elementDto.param = element.param;

        if (element.attachments) {
            element.attachments.forEach(attachment => {
                elementDto.attachments.push(AttachmentDto.fromModel(attachment));
            });
        }

        return elementDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.attachments = [];
        this.attachments.forEach(attachment => {
            if (attachment.modified) {
                clone.attachments.push(attachment.toBody());
            }
        });

        delete clone.previousValue;
        delete clone.possibleValues;

        return clone;
    }

    public toModel(): ChecklistElement {
        let element = new ChecklistElement();

        element.id = this.id;
        element.inspectionId = this.inspectionId;
        element.checklistId = this.checklistId;
        element.wording = this.wording;
        element.defaultValue = this.defaultValue;
        element.savedValue = this.savedValue;
        element.checklistModelId = this.checklistModelId;
        element.technicalLink = this.technicalLink;
        element.param = this.param;

        if (this.attachments) {
            this.attachments.forEach(attachment => {
                element.attachments.push(attachment.toModel());
            });
        }

        return element;
    }
}
