import { IdWording } from "../models/idWording.model";
import { BaseDto } from "./base/base.dto";

export class IdWordingDto extends BaseDto<IdWording> {
    public id: string = "";
    public wording: string = "";

    public static fromBody(body: any): IdWordingDto {
        let dto = new IdWordingDto();

        dto.id = "" + body.id;
        dto.wording = body.wording;

        return dto;
    }

    public static fromModel(idWording: IdWording): IdWordingDto {
        let dto = new IdWordingDto();

        dto.id = idWording.id;
        dto.wording = idWording.wording;

        return dto;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): IdWording {
        let model = new IdWording();

        model.id = this.id;
        model.wording = this.wording;

        return model;
    }

    public clone(): IdWordingDto {
        let clone = new IdWordingDto();

        clone.id = this.id;
        clone.wording = this.wording;

        return clone;
    }
}
