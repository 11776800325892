import { AttachmentKind } from "../models/attachmentKind.model";
import { BaseDto } from "./base/base.dto";

export class AttachmentKindDto extends BaseDto<AttachmentKind> {
    public id: string = "";
    public wording: string = "";

    static fromBody(body: any): AttachmentKindDto {
        let attachmentKindDto = new AttachmentKindDto();

        attachmentKindDto.id = "" + body.id;
        attachmentKindDto.wording = body.wording;

        return attachmentKindDto;
    }

    static fromModel(attachmentKind: AttachmentKind): AttachmentKindDto {
        let attachmentKindDto = new AttachmentKindDto();

        attachmentKindDto.id = attachmentKind.id;
        attachmentKindDto.wording = attachmentKind.wording;

        return attachmentKindDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): AttachmentKind {
        let attachmentKind = new AttachmentKind();

        attachmentKind.id = this.id;
        attachmentKind.wording = this.wording;

        return attachmentKind;
    }
}
