import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { AttachedDocument } from "./attachedDocument.model";
import { AttachmentKind } from "./attachmentKind.model";

export class Attachment extends ModelBase {
    static readonly TABLENAME = "Attachments";

    id: string = "";
    object: string = "";
    key: string = "";
    wording: string = "";
    attachmentKind: AttachmentKind;
    date: string = "";
    file: string = "";
    attachedDocuments: AttachedDocument[];
    txt: string = "";
    modified: number = 0;

    constructor() {
        super(Attachment.TABLENAME);

        this.attachedDocuments = [];
        this.attachmentKind = new AttachmentKind();
    }
}
