import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Equipment } from "../../models/equipment.model";

@Injectable({
    providedIn: "root",
})
export class EquipmentDbDao extends DbDaoBase<Equipment> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Equipment.TABLENAME + "_id"
                    + " ON " + Equipment.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Equipment.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "title TEXT, "
                    + "category TEXT, "
                    + "model TEXT, "
                    + "registrationNumber TEXT, "
                    + "serialNumber TEXT, "
                    + "mileage NUMERIC,"
                    + "mileageDate TEXT,"
                    + "mileage2 NUMERIC,"
                    + "mileageDate2 TEXT,"
                    + "inspectionModel TEXT,"
                    + "energy1 TEXT,"
                    + "energy2 TEXT,"
                    + "counter1 TEXT,"
                    + "counter2 TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Equipment> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                let equipment: Equipment = this.rowToModel(data.rows[0]);

                let hydratationPromises = [];

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return equipment;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Equipment.TABLENAME;
    }

    protected rowToModel(row: any): Equipment {
        let equipment = new Equipment();
        equipment.id = row.id;
        equipment.title = row.title;
        equipment.category = JSON.parse(row.category);
        equipment.model = row.model;
        equipment.registrationNumber = row.registrationNumber;
        equipment.serialNumber = row.serialNumber;

        equipment.mileage = Number(row.mileage);
        equipment.mileageDate = row.mileageDate;
        equipment.mileage2 =  Number(row.mileage2);
        equipment.mileageDate2 = row.mileageDate2;

        equipment.inspectionModel = row.inspectionModel;

        equipment.energy1 = JSON.parse(row.energy1);
        equipment.energy2 = JSON.parse(row.energy2);
        equipment.counter1 = JSON.parse(row.counter1);
        equipment.counter2 = JSON.parse(row.counter2);

        return equipment;
    }

    public save(equipment: Equipment): Promise<Equipment> {
        let query = "INSERT OR REPLACE INTO " + Equipment.TABLENAME + " ("
                    + "id, title, category, model, registrationNumber, serialNumber,"
                    + "mileage, mileageDate, mileage2, mileageDate2, inspectionModel, energy1, energy2, counter1, counter2"
                    + ") VALUES ("
                    + this.getValue(equipment.id)
                    + this.getValue(equipment.title)
                    + this.getValueAsJsonString(equipment.category)
                    + this.getValue(equipment.model)
                    + this.getValue(equipment.registrationNumber)
                    + this.getValue(equipment.serialNumber)
                    + this.getValue(equipment.mileage)
                    + this.getValue(equipment.mileageDate)
                    + this.getValue(equipment.mileage2)
                    + this.getValue(equipment.mileageDate2)
                    + this.getValueAsJsonString(equipment.inspectionModel)
                    + this.getValueAsJsonString(equipment.energy1)
                    + this.getValueAsJsonString(equipment.energy2)
                    + this.getValueAsJsonString(equipment.counter1)
                    + this.getValueAsJsonString(equipment.counter2, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return equipment;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
