import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { isNullOrEmpty } from "../../../helpers/null.helper";
import { HOUR_FORMAT } from "../../../interfaces/constants";
import { DateProvider } from "../../../interfaces/dateProvider";

@Component({
    selector: "time-picker-popover",
    templateUrl: "time-picker-popover.component.html",
    styleUrls: ["time-picker-popover.component.scss"],
})
export class TimePickerPopoverComponent implements OnInit {
    @Input() selectedTime: any;

    constructor(private popoverController: PopoverController,
                private dateProvider: DateProvider) {
    }

    ngOnInit(): void {
        if (isNullOrEmpty(this.selectedTime)) {
            this.selectedTime = this.dateProvider.now().toFormat(HOUR_FORMAT);
        }
    }

    async closeTimePickerPopover() {
        const modal = await this.popoverController.getTop();
        await modal.dismiss("");
    }

    async validateTimePickerPopover() {
        const modal = await this.popoverController.getTop();

        await modal.dismiss({ time: (this.selectedTime) });
    }
}



