import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/notImplementedError";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";
import { Face } from "../../../../models/inspection/v2/face.model";
import { ZoneDbDaoV2 } from "./zone.db.dao";

@Injectable({
    providedIn: "root",
})
export class FaceDbDaoV2 extends DbDaoBase<Face> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private zoneDbDao: ZoneDbDaoV2) {
        super(logger);
    }

    getList(ids: string[], inspectionId: string, hydrate: boolean = false): Promise<Face[]> {
        let selectQuery = "SELECT * FROM " + Face.TABLENAME + " WHERE id IN (";

        ids.forEach(id => {
            selectQuery += "'" + id + "',";
        });
        selectQuery = selectQuery.substring(0, selectQuery.length - 1);
        selectQuery += ") ORDER BY _order;";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let faces: Face[] = [];
                for (const item of data.rows) {
                    let face = this.rowToModel(item);
                    faces.push(face);
                }

                let hydratationPromises = [];

                if (hydrate) {
                    faces.forEach(face => {
                        hydratationPromises.push(this.zoneDbDao.getByFaceId(inspectionId, face.id, hydrate)
                            .then(value => {
                                face.zones = value;
                            }));
                    });
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return faces;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Face.TABLENAME + "_id"
                    + " ON " + Face.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Face.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "_order TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Face.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Face.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Face> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return Face.TABLENAME;
    }

    protected rowToModel(row: any): Face {
        let face = new Face();

        face.id = row.id;
        face.wording = row.wording;
        face.order = row._order;

        return face;
    }

    public save(face: Face): Promise<Face> {
        let promises = [];
        face.zones.forEach(zone => {
            promises.push(this.zoneDbDao.save(zone));
        });

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Face.TABLENAME + " (id, wording, _order) VALUES ("
                            + this.getValue(face.id)
                            + this.getValue(face.wording)
                            + this.getValue(face.order, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return face;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
